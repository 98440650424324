<template>
    <div id="datenschutz">
        <div class="legal">
            <h2>
                Datenschutzerklärung
            </h2>
            <p>
                Der Schutz und die Sicherheit von personenbezogenen Daten hat bei uns eine hohe Priorität. Nachfolgend
                werden Sie darüber informiert, welche Arten von personenbezogenen Daten zu welchem Zweck von uns
                verarbeitet werden.
            </p>
            <h4>
                1. Information über die Verarbeitung personenbezogener Daten
            </h4>
            <ol>
                <li>
                    Im Folgenden informieren wir über die Verarbeitung personenbezogener Daten bei Nutzung unserer
                    Webseite https://www.med-in-mind.de/ (im Folgenden <strong>„Webseite“</strong> genannt).
                    Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
                    E-Mail-Adressen, Nutzerverhalten.
                </li>
                <li>
                    Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist die med in mind GmbH,
                    vertreten durch die Geschäftsführerin Frau Kirsten Schwarz, Dachauer Str. 38, 80335 München,
                    Telefon: 0177 5989622, <a href="mailto:info@med-in-mind.de">info@med-in-mind.de</a> im Folgenden
                    als „wir“ oder „uns“ bezeichnet.
                </li>
                <li>
                    Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung des Schutzes personenbezogener
                    Daten. Diese werden dem aktuellen Stand der Technik jeweils angepasst.
                </li>
                <li>
                    Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder
                    Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie, untenstehend im Detail, über die
                    jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
                </li>
            </ol>
            <h4>
                2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung.
            </h4>
            <p>
                Ihre personenbezogenen Daten werden von uns in folgenden Zusammenhängen erhoben und gespeichert:
            </p>
            <h5>
                a) bei Besuch unserer Webseite
            </h5>
            <ol>
                <li>
                    Durch den bloßen Besuch der Webseite, wenn Sie sich nicht registrieren oder uns anderweitig
                    Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren
                    Server übermittelt. Wenn Sie unsere Webseite betrachten möchten, erheben wir die folgenden Daten,
                    die für uns technisch erforderlich sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität und
                    Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO – Verarbeitung
                    aufgrund eines berechtigten Interesses):
                    <ul>
                        <li>IP-Adresse</li>
                        <li>Datum und Uhrzeit der Anfrage</li>
                        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                        <li>Inhalt der Anforderung (konkrete Seite)</li>
                        <li>Zugriffsstatus/HTTP-Statuscode</li>
                        <li> jeweils übertragene Datenmenge</li>
                        <li>Webseite, von der die Anforderung kommt</li>
                        <li>Browser</li>
                        <li> Sprache und Version der Browsersoftware</li>
                        <li>Betriebssystem und dessen Oberfläche</li>
                        <li>Gerätetyp und einmalige Geräteidentifikationsnummern</li>
                        <li>den groben geografischen Standort (z. B. Land oder Stadtebene)</li>
                        <li>Informationen darüber, wie Ihr Gerät mit unserer Webseite interagiert hat</li>
                        <li>die Besuchsdauer unserer Webseite</li>
                        <li>sonstige technische Informationen</li>
                    </ul>
                </li>
                <li>
                    Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
                    <ul>
                        <li>
                            Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite
                        </li>
                        <li>
                            Gewährleistung einer komfortablen Nutzung unserer Webseite
                        </li>
                        <li>
                            Auswertung der Systemsicherheit und -stabilität, sowie zur Qualitätssicherung
                        </li>
                        <li>
                            Auswertung des Benutzerverhaltens und Optimierung unseres Angebots entsprechend der
                            Benutzerinteressen
                        </li>
                        <li>
                            zu weiteren administrativen Zwecken
                        </li>
                    </ul>
                </li>
            </ol>
            <p>
                Die Rechtsgrundlage für die Datenverarbeitung unter diesem Abschnitt a) ist Art. 6 Abs. 1 S. 1 lit. f
                DSGVO.
            </p>
            <ol>
                <li>
                    Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
                </li>
                <li>
                    In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu
                    ziehen.
                </li>
            </ol>
            <h5>
                b) bei Nutzung des Kontaktformulars
            </h5>
            <ol>
                <li>
                    Bei Ihrer Kontaktaufnahme mit uns über das Kontaktformular werden die von Ihnen mitgeteilten Daten
                    (Name, E-Mail, Nachricht) von uns gespeichert, soweit dies erforderlich ist.
                </li>
                <li>
                    Die Verarbeitung erfolgt insoweit zum Zwecke der Kontaktaufnahme per E-Mail als vorvertragliche
                    Maßnahme (Art. 6 Abs. 1 S. 1 lit. b DSGVO), aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1
                    lit. a DSGVO und auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                </li>
            </ol>
            <h5>
                c) bei eigeninitiativer Zusendung von Daten per E-Mail
            </h5>
            <ol>
                <li>
                    Bei Ihrer Kontaktaufnahme mit uns über eine – von Ihnen initiativ versandten – E-Mail werden die von
                    Ihnen mitgeteilten Daten (Name, E-Mail-Adresse, Nachricht, Signatur- und Kontaktdaten, sowie etwaige
                    Datei-Anhänge) von uns gespeichert, soweit dies erforderlich ist. Andere Daten werden ohne eine
                    weitere Verarbeitung durch uns unmittelbar gelöscht. Die in diesem Zusammenhang anfallenden Daten
                    löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung
                    ein, falls gesetzliche Aufbewahrungspflichten bestehen.
                </li>
                <li>
                    Die Verarbeitung erfolgt insoweit zum Zwecke der Kontaktaufnahme per E-Mail als vorvertragliche
                    Maßnahme (Art. 6 Abs. 1 S. 1 lit. b DSGVO) oder auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                </li>
            </ol>
            <h5>
                d) im Telefonat
            </h5>
            <ol>
                <li>
                    Bei Ihrer telefonischen Kontaktaufnahme mit uns werden die von Ihnen mitgeteilten Daten, von uns
                    gespeichert, soweit dies erforderlich ist. Die in diesem Zusammenhang anfallenden Daten löschen wir,
                    nachdem die Speicherung nicht mehr erforderlich ist oder schränken die Verarbeitung ein falls
                    gesetzliche Aufbewahrungspflichten bestehen. Alle anderen Daten werden ohne eine weitere
                    Verarbeitung durch uns unmittelbar gelöscht.
                </li>
                <li>
                    Die Verarbeitung erfolgt insoweit zum Zwecke der telefonischen Kontaktaufnahme als vorvertragliche
                    Maßnahme (Art. 6 Abs. 1 S. 1 lit. b DSGVO) oder auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO.
                </li>
            </ol>
            <p>
                Persönliche Daten werden nur erhoben oder verarbeitet, wenn Sie diese Angaben freiwillig, z. B. im
                Rahmen einer Anfrage mitteilen. Sofern keine erforderlichen Gründe im Zusammenhang mit einer
                Geschäftsabwicklung bestehen, können Sie jederzeit die zuvor erteilte Genehmigung Ihrer persönlichen
                Datenspeicherung mit sofortiger Wirkung schriftlich (z. B. per E-Mail oder per Fax) widerrufen. Ihre
                Daten werden nicht an Dritte übertragen, es sei denn, eine Weitergabe ist aufgrund gesetzlicher
                Vorschriften erforderlich.
            </p>
            <p>
                Wenn für uns keine fortdauernde und berechtigte geschäftliche Notwendigkeit für die Verarbeitung Ihrer
                personenbezogenen Daten besteht, werden wir sie entweder löschen oder anonymisieren oder, falls dies
                nicht möglich ist (z. B., weil Ihre personenbezogenen Daten in Backup-Archiven gespeichert wurden),
                werden wir Ihre personenbezogenen Daten sicher speichern und sie von einer weiteren Verarbeitung
                herausnehmen, bis die Löschung möglich ist.
            </p>
            <h4>
                3. Ihre Rechte
            </h4>
            <ol>
                <li>
                    Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
                    <ul>
                        <li>Recht auf Auskunft.</li>
                        <li>Recht auf Berichtigung oder Löschung.</li>
                        <li>Recht auf Einschränkung der Verarbeitung.</li>
                        <li>Recht auf Widerspruch gegen die Verarbeitung.</li>
                        <li>Recht auf Datenübertragbarkeit.</li>
                    </ul>
                </li>
                <li>
                    Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                    personenbezogenen Daten durch uns zu beschweren.
                </li>
            </ol>
            <h4>
                4. Widerspruchsrecht
            </h4>
            <ol>
                <li>
                    Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S.
                    1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
                    Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus
                    Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
                    letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen
                    Situation von uns umgesetzt wird.
                </li>
                <li>
                    Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
                    <a href="mailto:info@med-in-mind.de">info@med-in-mind.de.</a>
                </li>
            </ol>
            <h4>
                5. Sicherheit Ihrer Daten
            </h4>
            <ol>
                <li>
                    Wir verwenden innerhalb des Webseite-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in
                    Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
                    In der Regel handelt es sich dabei um eine 256-Bit-Verschlüsselung. Falls Ihr Browser keine
                    256-Bit-Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit-v3-Technologie zurück. Ob
                    eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der
                    geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in Ihrem Browser.
                </li>
                <li>
                    Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um
                    Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust,
                    Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden
                    entsprechend der technologischen Entwicklung fortlaufend verbessert.
                </li>
            </ol>
            <h4>
                6. Hinweis zu Google Analytics
            </h4>
            <p>
                Unsere Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc., 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics verwendet Cookies, die eine Analyse
                der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
                Benutzung dieser Webseite (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA
                übertragen und dort gespeichert. Wir weisen darauf hin, dass unsere Webseite die Erweiterung
                „anonymizeIp()“ (IP-Anonymisierung) verwendet, die bewirkt, dass Ihre IP-Adresse von Google innerhalb
                der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
                Wirtschaftsraum vor einer Speicherung und Übermittlung um das letzte Oktett gekürzt wird, sodass ein
                unmittelbarer Personenbezug ausgeschlossen ist. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                Server von Google in den USA übertragen und dort gekürzt. Google wird diese Informationen in unserem
                Auftrag benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten für
                uns zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene
                Dienstleistungen an uns zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
                IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Erfassung der durch
                das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) sowie
                die Verarbeitung dieser Daten durch Google verhindern, indem Sie Ihren Browser entsprechend einstellen.
                Auf dieser Webseite nutzen wir ferner Google Conversion Tracking. Conversion Tracking hilft uns, unser
                Online-Marketing zu verbessern. Mit Hilfe des Conversion Trackings erhalten wir Nutzungsstatistiken über
                diejenigen Nutzer, die über eine Google-Anzeige auf unsere Webseite gelangen. Dadurch können wir
                nachvollziehen, wie erfolgreich einzelne Google-Anzeigen sind. Wenn Sie über eine Google-Anzeige auf
                unsere Webseite gelangt sind, setzt Google AdWords ein Cookie auf Ihrem Rechner. Dieses Cookie verliert
                nach 30 Tagen seine Gültigkeit und übermittelt uns keine Informationen, mit denen wir Sie persönlich
                identifizieren könnten. Besuchen Sie bestimmte Bereiche unserer Webseite und ist das Cookie noch nicht
                abgelaufen, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite
                weitergeleitet wurden. Wenn Sie nicht an dem Tracking-Verfahren teilnehmen möchten, können Sie das
                hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische
                Setzen von Cookies generell deaktiviert. Sie können Cookies für Conversion-Tracking auch deaktivieren,
                indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „googleadservices.com“ blockiert
                werden. Bitte beachten Sie in diesem Zusammenhang auch die Datenschutz-Richtlinien von Google, die
                unabhängig von unserer Datenschutzerklärung gelten.
            </p>
            <h4>
                7. Hinweis zu Cookies
            </h4>
            <p>
                Auf unserer Webseite verwenden wir Cookies, um Ihnen die Nutzung unserer Webseite zu erleichtern. Dabei
                handelt es sich um kleine Textdateien, die entweder nur für die Dauer Ihres Besuchs unserer Webseite
                oder für einen bestimmten Zeitraum auf Ihrer Festplatte hinterlegt und abhängig von der Einstellung
                Ihres Browser-Programms beim Beenden des Browsers wieder gelöscht werden. Diese Cookies rufen keine auf
                Ihrer Festplatte über Sie gespeicherten Informationen ab und beeinträchtigen nicht Ihren PC oder Ihre
                Dateien. Die meisten Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Sie können
                das Speichern von Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass er Sie auf die
                Sendung von Cookies hinweist.
            </p>
            <h4>
                8. Cookies
            </h4>
            <p>
                Einsatz von Cookies:
            </p>
            <ol>
                <li>
                    Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden
                    erläutert werden:
                    <ul>
                        <li>Transiente Cookies (dazu (2))</li>
                        <li>Persistente Cookies (dazu (3)).</li>
                    </ul>
                </li>
                <li>
                    Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen
                    insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich
                    verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr
                    Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden
                    gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
                </li>
                <li>
                    Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach
                    Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers
                    jederzeit löschen.
                </li>
                <li>
                    Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z&#183;B&#183; die
                    Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie
                    eventuell nicht alle Funktionen dieser Website nutzen können.
                </li>
                <li>
                    Weiterhin nutzen wir HTML5-storage-objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte
                    speichern die erforderlichen Daten und haben kein automatisches Ablaufdatum. Die Nutzung von
                    HTML5-storage-objects können Sie verhindern, indem Sie in Ihrem Browser den privaten Modus
                    einsetzen. Zudem empfehlen wir regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu löschen.
                </li>
            </ol>
            <h4>
                9. Änderungen dieser Datenschutzbestimmungen
            </h4>
            <p>
                Wir werden diese Richtlinien zum Schutz Ihrer persönlichen Daten von Zeit zu Zeit aktualisieren. Sie
                sollten sich diese Richtlinien gelegentlich ansehen, um auf dem Laufenden darüber zu bleiben, wie wir
                Ihre Daten schützen und die Inhalte unserer Website stetig verbessern. Sollten wir wesentliche
                Änderungen bei der Sammlung, der Nutzung und/oder der Weitergabe der uns von Ihnen zur Verfügung
                gestellten personenbezogenen Daten vornehmen, werden wir Sie durch einen eindeutigen und gut sichtbaren
                Hinweis auf der Website darauf aufmerksam machen. Mit der Nutzung der Webseite erklären Sie sich mit den
                Bedingungen dieser Richtlinien zum Schutz persönlicher Daten einverstanden.
            </p>
            <h4>
                10. Aktualisierungen dieser Datenschutzerklärung
            </h4>
            <p>
                Sie können erkennen, wann diese Datenschutzerklärung zuletzt aktualisiert wurde, indem Sie das Datum der
                „letzten Aktualisierung“ am Ende dieser Datenschutzerklärung prüfen.
            </p>
            <h4>
                11. Informationspflichten im Bewerbungsverfahren nach Art. 13 DSGVO
            </h4>
            <p>
                (a) Art und Zweck der Verarbeitung
            </p>
            <p>
                Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen des Bewerbungsverfahrens im Einklang mit
                den gesetzlichen Vorgaben. Die Verarbeitung der Bewerberdaten erfolgt zur Erfüllung unserer
                (vor)vertraglichen Verpflichtungen im Rahmen des Bewerbungsverfahrens, sofern die Datenverarbeitung z.B.
                im Rahmen von rechtlichen Verfahren für uns erforderlich wird. Das Bewerbungsverfahren setzt voraus,
                dass Bewerber uns die Bewerberdaten mitteilen. Die notwendigen Bewerberdaten sind, sofern wir ein
                Onlineformular anbieten, gekennzeichnet, ergeben sich sonst aus den Stellenbeschreibungen und
                grundsätzlich gehören dazu die Angaben zur Person, Post- und Kontaktadressen und die zur Bewerbung
                gehörenden Unterlagen, wie Anschreiben, Lebenslauf und die Zeugnisse. Daneben können uns Bewerber
                freiwillig zusätzliche Informationen mitteilen. Mit der Übermittlung der Bewerbung an uns, erklären sich
                die Bewerber mit der Verarbeitung ihrer Daten zu Zwecken des Bewerbungsverfahrens der in dieser
                Datenschutzerklärung dargelegten Art und Umfang einverstanden. Sofern zur Verfügung gestellt, können uns
                Bewerber ihre Bewerbungen mittels eines Onlineformulars auf unserer Website übermitteln. Die Daten
                werden entsprechend dem Stand der Technik verschlüsselt an uns übertragen. Ferner können Bewerber uns
                ihre Bewerbungen via E-Mail jobs@med-in-mind.de übermitteln. Hierbei bitten wir jedoch zu beachten, dass
                E-Mails grundsätzlich nicht verschlüsselt versendet werden und die Bewerber selbst für die
                Verschlüsselung sorgen müssen. Wir können daher für den Übertragungsweg der Bewerbung zwischen dem
                Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen und empfehlen daher eher ein
                Online-Formular oder den postalischen Versand zu nutzen. Die von den Bewerbern zur Verfügung gestellten
                Daten, können im Fall einer erfolgreichen Bewerbung für die Zwecke des Beschäftigungsverhältnisses von
                uns weiterverarbeitet werden.
            </p>
            <p>
                (b) Rechtliche Grundlage der Verarbeitung
            </p>
            <p>
                Die Verarbeitung Ihrer Daten dient in erster Linie der Begründung des Beschäftigungsverhältnisses gemäß
                Art. 88 Abs. 1 DS-GVO i. V. m. § 26 Abs. 1 BDSG.
            </p>
            <p>
                (c) Datenkategorien
            </p>
            <p>
                Vor-, Nachname, Adresse, Emailadresse, Telefonnummer, Lebenslauf, Zeugnisse, Zertifikate, Foto,
                Dokumente, die Sie uns unaufgefordert zukommen lassen.
            </p>
            <p>
                Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere Kategorien von personenbezogenen Daten im
                Sinne des Art. 9 Abs. 1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs.
                2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft oder ethnische Herkunft).
            </p>
            <p>
                (d) Empfänger
            </p>
            <p>
                Empfänger der Daten sind interne Mitarbeiter der med in mind GmbH.
            </p>
            <p>
                (e) Speicherfristen
            </p>
            <p>
                Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem Ablauf eines
                Zeitraums von sechs Monat(en), damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und
                unseren Nachweispflichten aus dem Gleichbehandlungsgesetz genügen können. Rechnungen über etwaige
                Reisekostenerstattung werden entsprechend den steuerrechtlichen Vorgaben archiviert.
            </p>
            <p>
                (f) Gesetzliche / vertragliche Erfordernis
            </p>
            <p>
                Die Bereitstellung Ihrer personenbezogenen Daten über die Speicherfrist hinaus, z.B. um in unserem
                Bewerberpool aufgenommen zu werden, erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Diese
                Einwilligung zur Speicherung Ihrer persönlichen Daten können Sie jederzeit mit Wirkung für die Zukunft
                widerrufen.
            </p>
            <p>
                (g) Drittstaatentransfer
            </p>
            <p>
                Die Verarbeitung erfolgt nicht außerhalb der Europäischen Union (EU) oder des Europäischen
                Wirtschaftsraums (EWR).
            </p>
            <p>
                (h) Widerruf der Einwilligung
            </p>
            <p>
                Sofern die Bewerbung auf ein Stellenangebot nicht erfolgreich ist, werden die Daten der Bewerber
                gelöscht. Die Daten der Bewerber werden ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird, wozu
                die Bewerber jederzeit berechtigt sind. Die Einwilligung zur Speicherung Ihrer persönlichen Daten, über
                die Speicherfrist hinaus, können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Sie können uns
                jederzeit Ihren Widerruf über die am Anfang dieser Datenschutzhinweise angegebene Kontaktmöglichkeit
                mitteilen.
            </p>
            <p>
                (i) Automatisierte Entscheidungsfindung und Profiling
            </p>
            <p>
                Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder
                ein Profiling bei dieser Datenverarbeitung.
            </p>
            <h4>
                12. Verarbeitung im Rahmen der Geschäftsbeziehung (Informationspflichten für Kunden nach Art. 13 DSGVO)
            </h4>
            <p>
                (a) Art und Zweck der Verarbeitung
            </p>
            <p>
                Wir können die personenbezogenen Daten unserer Kunden, Interessenten, Lieferanten, Verkäufer und Partner
                verarbeiten für Kommunikation, Planung, Durchführung des Vertragsverhältnisses, Marketing, Verwaltung
                und Sicherheitszwecke.
            </p>
            <p>
                (b) Rechtsgrundlage der Verarbeitung
            </p>
            <p>
                Die Verarbeitung der bereitgestellten Daten beruht auf einem berechtigten Interesse (Art. 6 Abs. 1 lit.
                f DSGVO) und der Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO)
            </p>
            <p>
                (c) Datenkategorien
            </p>
            <p>
                Kontaktinformationen (vollständiger Name, Berufsbezeichnung, berufliche E-Mail-Adresse, berufliche
                Telefonnummer, berufliche Anschrift), Abrechnungsinformationen und Zahlungsdaten, Weitere notwendige
                Informationen in einem Projekt- oder Vertragsverhältnis oder Informationen, die uns freiwillig zur
                Verfügung gestellt werden, wie z.B. personenbezogene Daten in Bezug auf Bestellungen, Zahlungen,
                Anfragen und Projekte.
            </p>
            <p>
                (d) Empfänger
            </p>
            <p>
                Empfänger der Daten sind die internen Mitarbeiter der jeweiligen Abteilungen und ggf. die
                Auftragsverarbeiter der Abteilungen.
            </p>
            <p>
                (e) Aufbewahrungsfristen
            </p>
            <p>
                Wir löschen personenbezogene Daten, wenn die Speicherung der personenbezogenen Daten für die Zwecke, für
                die sie erhoben oder verarbeitet wurden, oder zur Erfüllung gesetzlicher Pflichten (z.B. HGB, AO) nicht
                mehr erforderlich ist.
            </p>
            <p>
                (f) Übermittlung in Drittländer
            </p>
            <p>
                Ihre personenbezogenen Daten können an Dritte mit Sitz außerhalb der Europäischen Union (EU) oder des
                Europäischen Wirtschaftsraums (EWR) übermittelt werden, die für uns z.B. Hosting-Dienste erbringen. Um
                das Datenschutzniveau im Drittland zu gewährleisten, haben wir mit unseren jeweiligen Dienstleistern
                sogenannte Standard-Datenschutzklauseln abgeschlossen.
            </p>
            <p>
                (g) Automatisierte Entscheidungsfindung und Profiling
            </p>
            <p>
                Als verantwortungsbewusstes Unternehmen verzichten wir bei dieser Datenverarbeitung auf eine
                automatische Entscheidungsfindung oder ein Profiling.
            </p>
            <h4>
                13. Kontakt
            </h4>
            <p>
                Bei weiteren Fragen zur Verarbeitung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung,
                Sperrung oder Löschung von Daten wenden Sie sich bitte an unseren Datenschutzbeauftragten:
            </p>
            <p>
                Bugl & Kollegen Gesellschaft für Datenschutz und Informationssicherheit mbH<br>
                Alexander Bugl<br>
                Eifelstraße 55<br>
                93057 Regensburg<br>
                E-Mail: <a href="mailto:kontakt@buglundkollegen.de">kontakt@buglundkollegen.de</a>
            </p>
            <p>
                Namentlich gemeldet bei der Aufsichtsbehörde:<br>
                Alexander Bugl<br>
                E-Mail: <a href="mailto:ab@buglundkollegen.de">ab@buglundkollegen.de</a>
            </p>
            <br>
            <p>
                Letzte Aktualisierung: 02.01.2024
            </p>
            <p>
                Zurück zur
            </p>
            <p>
                <router-link
                    class="button button-red"
                    :to="{ name: 'start' }"
                >
                    Startseite
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
        window.console.log('Datenschutz mounted');
        this.updateMetaTags();
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            metaTags : {
                'meta[name="description"]' : '',
                'meta[property="og:description"]' : '',
                'meta[property="og:title"]' : '',
                'meta[property="og:url"]' : 'http://www.med-in-mind.de/datenschutz',
            },
        };
    },

    methods : {
        updateMetaTags(){
            window.console.log('updateMetaTags', this.$route.path, this.metaTags);
            if (this.metaTags) {
                for (let [key, value] of Object.entries(this.metaTags)) {
                    document.querySelector(key).setAttribute('content', value);
                }
            }
        },
    },

    props : [],

    watch : {},

}

</script>

<style lang="scss">

@import "../assets/css/variables";

#datenschutz {
    padding: 96px 120px 48px 72px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 72px 16px 16px 16px;
    }
}

.legal {
    a {
        color: $color-medinmind-red;
    }
    p {
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 14px;
        }
    }
    h2 {
        margin: 0 0 24px 0;
        font-family: $font-family-avenir-medium;
        font-size: 48px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 24px;
        }
    }
    h4 {
        margin: 16px 0;
        font-family: $font-family-avenir-black;
        font-size: 16px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 14px;
        }
    }
    h5 {
        margin: 16px 0;
        font-family: $font-family-avenir-black;
        font-size: 16px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 14px;
        }
    }
    ol, ul {
        margin-top: 16px;
        margin-bottom: 16px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            padding-inline-start: 16px;
        }
        li {
            margin-top: 16px;
            margin-bottom: 16px;
            font-size: 16px;
            @media screen and (max-width: 767px), screen and (max-height: 511px) {
                font-size: 14px;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    > ol, ul {
        > li {
            ol, ul {
                li {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}

</style>
