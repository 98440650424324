<template>
    <section id="dafuer-stehen-wir" class="page">
        <div class="background no-gradient no-video">
            <video autoplay loop muted>
                <source
                    src="../assets/videos/25844345-hd.mp4"
                    type="video/mp4"
                >
            </video>
        </div>
        <div
            class="articles"
            @mouseover="articleUpdatePause = true"
            @mouseout="articleUpdatePause = false"
        >
            <h1 class="page-title">
                Dafür stehen wir
            </h1>
            <div class="article-container">
                <article
                    @click="articlePopup = 'transparenz'"
                    :style="{ 'margin-left' : 'calc(-' + (articleIndex * 100) + '%)' }"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Transparenz
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup popup-transparenz"
                        :class="{ 'active' : articlePopup === 'transparenz' }"
                    >
                        <h3>
                            [ Transparenz ]
                        </h3>
                        <p>
                            Von der Angebotserstellung bis zur Evaluation –
                            bei uns liegen alle Karten auf dem Tisch.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'wissenschaftliche-kompetenz'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Wissenschaftliche<br>
                        Kompetenz
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'wissenschaftliche-kompetenz' }"
                    >
                        <h3>
                            [ Wissenschaftliche Kompetenz ]
                        </h3>
                        <p>
                            Erklärungsbedürftige Rx-Indikationen? Kein Problem für uns! Mit unserem Team aus Biologen,
                            Gesundheits&shy;wissenschaftlern und Medizinern bringen wir komplexe medizinische Inhalte auf
                            den Punkt.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'journalistisches-know-how'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Journalistisches<br>
                        Know-how
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'journalistisches-know-how' }"
                    >
                        <h3>
                            [ Journalistisches Know-how ]
                        </h3>
                        <p>
                            „Schreibe kurz – und sie werden es lesen. Schreibe klar – und sie werden es verstehen.
                            Schreibe bildhaft – und sie werden es im Gedächtnis behalten.“
                            <br>
                            (Joseph Pulitzer)
                        </p>
                        <p>
                            Unsere Wissenschafts- und Publikumsredakteure garantieren eine gleichbleibend hohe
                            Textqualität.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'engagement'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Engagement
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'engagement' }"
                    >
                        <h3>
                            [ Engagement ]
                        </h3>
                        <p>
                            Einsatz und Hingabe sind bei uns keine Worthülsen – für die gesteckten Ziele gehen wir die
                            Extrameile.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'kundennaehe'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Kundennähe
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'kundennaehe' }"
                    >
                        <h3>
                            [ Kundennähe ]
                        </h3>
                        <p>
                            Auf uns ist Verlass – Kundenorientierung, Qualität und Zuverlässigkeit zeichnen unsere
                            Arbeitsweise
                            aus.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'integrierte-kommunikation'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Integrierte<br>
                        Kommunikation
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'integrierte-kommunikation' }"
                    >
                        <h3>
                            [ Integrierte Kommunikation ]
                        </h3>
                        <p>
                            Fachübergreifende Ansätze für bestmöglichen Output.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'multi-channel-marketing'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Multi-Channel-<br>
                        Marketing
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'multi-channel-marketing' }"
                    >
                        <h3>
                            [ Multi-Channel-Marketing ]
                        </h3>
                        <p>
                            Ob Print, Digital, TV oder Hörfunk – durch die Vernetzung der klassischen und digitalen
                            Kanäle verbreiten wir Ihre Botschaft an die relevanten Zielgruppen.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'strategische-beratung'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Strategische Beratung
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'strategische-beratung' }"
                    >
                        <h3>
                            [ Strategische Beratung ]
                        </h3>
                        <p>
                            Auf Basis fundierter Analysen entwickeln wir maßgeschneiderte Kommunikationslösungen –
                            passgenau zu Ihrer Zielsetzung.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
                <article
                    @click="articlePopup = 'ideenreichtum'"
                >
                    <h1 class="page-title">
                        Dafür stehen wir
                    </h1>
                    <h3 class="value">
                        Ideenreichtum
                    </h3>
                    <div class="article-action"></div>
                    <aside
                        class="popup"
                        :class="{ 'active' : articlePopup === 'ideenreichtum' }"
                    >
                        <h3>
                            [ Ideenreichtum ]
                        </h3>
                        <p>
                            Gehen Sie mit uns neue Wege in der Gesundheitskommunikation – mit Fantasie und Kreativität
                            machen wir Ihre Inhalte zu einem Erlebnis, schaffen Sympathie für Ihre Marke und erzählen
                            Geschichten, die im Gedächtnis bleiben.
                        </p>
                        <div
                            class="popup-action"
                            @click.stop="articlePopup = ''"
                        ></div>
                    </aside>
                </article>
            </div>
            <nav class="article-indicators">
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 0 }"
                    @click="toArticleIndex(0)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 1 }"
                    @click="toArticleIndex(1)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 2 }"
                    @click="toArticleIndex(2)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 3 }"
                    @click="toArticleIndex(3)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 4 }"
                    @click="toArticleIndex(4)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 5 }"
                    @click="toArticleIndex(5)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 6 }"
                    @click="toArticleIndex(6)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 7 }"
                    @click="toArticleIndex(7)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 8 }"
                    @click="toArticleIndex(8)"
                ></div>
            </nav>
            <nav class="article-chevrons">
                <div
                    class="article-chevron"
                    @click="toPreviousArticle()"
                ></div>
                <div
                    class="article-chevron"
                    @click="toNextArticle()"
                ></div>
            </nav>
        </div>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#dafuer-stehen-wir'
        }"></div>
    </section>
</template>

<script>

import Hammer from 'hammerjs';

const UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS = 3000;

export default {

    components : {},

    created(){
    },
    destroyed(){
        this.reset();
        this.articleContainerHammer.off('swipeleft swiperight');
        this.articleContainerHammer.destroy();
    },
    mounted(){
        window.console.log('DafuerStehenWir mounted');
        this.articleContainerHammer = new Hammer(this.$el.querySelector('.article-container'));
        this.articleContainerHammer.on('swipeleft', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toNextArticle();
        });
        this.articleContainerHammer.on('swiperight', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toPreviousArticle();
        });
        if (this.$route.hash === '#dafuer-stehen-wir') {
            this.init();
        }
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            articleContainerHammer : null,
            articleIndex : 0,
            articlePopup : null,
            articleUpdateInterval : null,
            articleUpdatePause : true,
        };
    },

    methods : {
        init(){
            this.initArticleUpdateInterval();
        },
        initArticleUpdateInterval(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleUpdatePause = false;
            this.articleUpdateInterval = window.setInterval(()=>{
                if (this.articleUpdatePause === true) {
                    return;
                }
                if (
                    this.$root.states.breakpoints.maxWidth1439 === false &&
                    this.$root.states.breakpoints.maxHeight767 === false
                ) {
                    return;
                }
                this.updateArticle();
            }, UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS);
        },
        reset(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleIndex = 0;
            this.articlePopup = null;
            this.articleUpdatePause = true;
        },
        toArticleIndex(articleIndex){
            this.initArticleUpdateInterval();
            this.articleIndex = articleIndex;
        },
        toNextArticle(){
            if (this.articleIndex < 8) {
                this.articleIndex++;
                return;
            }
            this.articleIndex = 0;
        },
        toPreviousArticle(){
            if (this.articleIndex > 0) {
                this.articleIndex--;
                return;
            }
            this.articleIndex = 8;
        },
        updateArticle(){
            this.toNextArticle();
        },
    },

    props : [],

    watch : {
        $route(){
            this.reset();
            if (this.$route.hash === '#dafuer-stehen-wir') {
                this.init();
            }
        },
    },

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#dafuer-stehen-wir {
    display: flex;
    flex-direction: column;
}

.articles {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: auto;
    margin: 0 auto;
    background-color: $color-medinmind-white;
    text-align: center;
    @media screen and (min-width: 768px) and (min-height: 768px) {
        width: 85%;
        min-width: 400px;
        height: auto;
        margin: auto;
        padding: 24px 0 0 0;
    }
    @media screen and (min-width: 960px) and (min-height: 768px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 768px) {
        width: 75%;
        max-width: none;
    }
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        width: 75%;
        max-width: 1280px;
    }
    @media screen and (min-width: 1920px) and (min-height: 768px) {
    }
}

.article-action {
    display: none;
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        display: block;
        width: 46px;
        height: 40px;
        margin: auto auto 24px auto;
        cursor: pointer;
        background-image: url(../assets/images/ic_popup.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.article-chevrons {
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        display: none;
    }
    .article-chevron {
        position: absolute;
        top: 50%;
        width: (88px / 4);
        height: (176px / 4);
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        transform: translateY(-50%);
        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            display: none;
        }
        &:first-child {
            left: 24px;
            background-image: url(../assets/images/ic_chevron_left.svg);
        }
        &:last-child {
            right: 24px;
            background-image: url(../assets/images/ic_chevron_right.svg);
        }
    }
}

.article-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        flex-wrap: wrap;
        padding: 24px;
        overflow: visible;
    }
    article {
        flex-shrink: 0;
        position: relative;
        width: 100%;
        padding: 32px 72px 96px 72px;
        text-align: center;
        transition: margin-left 400ms;
        will-change: margin-left;
        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            padding: 32px 24px 64px 24px;
        }
        @media screen and (min-width: 1440px) and (min-height: 768px) {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            width: calc(33.33333333% - 24px);
            min-height: 144px;
            margin: 12px !important;
            padding: 0;
            background-color: $color-medinmind-white;
            box-shadow: 0 24px 36px 0 rgba(0, 0, 0, 0.05);
            cursor: pointer;
            overflow: visible;
            &:hover {
                box-shadow: 0 24px 36px 0 rgba(0, 0, 0, 0.15);
                .value {
                    font-family: $font-family-avenir-black;
                    font-weight: normal;
                }
            }
        }
    }
}

.article-indicators {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        display: none;
    }
    .article-indicator {
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 12px 0 0;
        background-color: $color-medinmind-white;
        border: 1px solid $color-medinmind-red;
        border-radius: 50%;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background-color: $color-medinmind-red;
        }
    }
}

.background {
    &.no-video {
        background-image: url(../assets/images/25844345-hd.jpg);
    }
}

.page {
    padding: 96px 72px 72px 72px;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 48px 0 0 0;
    }
}

.page-title {
    .articles > & {
        display: none;
        @media screen and (min-width: 1440px) and (min-height: 768px) {
            display: inline;
        }
    }
    article > & {
        @media screen and (min-width: 1440px) and (min-height: 768px) {
            display: none;
        }
    }
}

.popup {
    display: block;
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        width: calc(100% + 24px);
        min-height: calc(100% + 24px);
        padding: 24px 48px 24px 24px;
        background-color: $color-medinmind-red;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        &.active {
            display: block;
        }
    }
    h3 {
        display: none;
        @media screen and (min-width: 1440px) and (min-height: 768px) {
            display: block;
            margin: 0 0 12px 0;
            color: $color-medinmind-white;
            text-align: left;
        }
    }
    p {
        padding: 0 48px;
        color: $color-medinmind-black;
        text-align: center;
        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            padding: 0;
        }
        @media screen and (min-width: 1440px) and (min-height: 768px) {
            color: $color-medinmind-white;
            padding: 0;
            text-align: left;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.popup-action {
    display: none;
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background-image: url(../assets/images/ic_x.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.value {
    font-family: $font-family-avenir-book;
    font-size: 20px;
    font-weight: normal;
}

</style>
