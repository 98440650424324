import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.config.productionTip = false;

import App from './App.vue';

import router from './router';

import storage from './services/storage';

storage.events = new Vue();
storage.states.activeScrollSection = null;
storage.states.breakpoints = {
    maxWidth767 : false,
    maxWidth959 : false,
    maxWidth1439 : false,
    maxHeight511 : false,
    maxHeight767 : false,
};
storage.states.isScrolling = false;
storage.states.menuIsVisible = false;
storage.states.scrollSections = {
    '#unser-portfolio' : 0,
    // '#erkunden-sie-uns' : 0,
    '#wer-wir-sind' : 0,
    '#dafuer-stehen-wir' : 0,
    '#was-wir-fuer-sie-tun-koennen' : 0,
    '#statements-unserer-kunden' : 0,
    '#unsere-referenzen' : 0,
    '#unsere-familie' : 0,
    '#unsere-hotline' : 0,
};

import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
    // autoTracking : {
    //     pageviewOnLoad : false,
    // },
    disabled : true,
    id : 'UA-93260698-9',
    router,
});

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    load : {
        key : 'AIzaSyCAsKmGvmNGL-eq5_V0YvWKLjsFgFYBwg8',
    },
});

new Vue({
    data : {
        cache : storage.cache,
        events : storage.events,
        global : storage.global,
        messages : storage.messages,
        promises : storage.promises,
        states : storage.states,
        translations : storage.translations,
    },
    router,
    render : h=>h(App),
}).$mount('#app');

window.addEventListener('CookiebotOnAccept', function(){
    window.console.log('CookiebotOnAccept', window.Cookiebot);
    if (window.Cookiebot) {
        if (window.Cookiebot.consent.marketing) {
            Vue.$ga.enable();
        } else {
            Vue.$ga.disable();
        }
    }
}, false);

window.addEventListener('CookiebotOnDecline', function(){
    window.console.log('CookiebotOnDecline');
    Vue.$ga.disable();
}, false);
