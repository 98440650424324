import { render, staticRenderFns } from "./UnserPortfolio.vue?vue&type=template&id=084fde82&scoped=true&"
import script from "./UnserPortfolio.vue?vue&type=script&lang=js&"
export * from "./UnserPortfolio.vue?vue&type=script&lang=js&"
import style0 from "./UnserPortfolio.vue?vue&type=style&index=0&id=084fde82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084fde82",
  null
  
)

export default component.exports