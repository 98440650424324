<template>
    <section id="unser-portfolio" class="page">
        <div class="background no-gradient"></div>
        <div class="page-title-container">
            <h1 class="page-title">
                med in mind
            </h1>
            <h3 class="sub-title">
                Ihre Healthcare Marketing- und<br>
                PR-Agentur in München.
            </h3>
        </div>
        <h2 class="title" v-html="titles[titleIndex]"></h2>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#unser-portfolio'
        }"></div>
    </section>
</template>

<script>

const UPDATE_TITLE_INTERVAL_DELAY_IN_MS = 500;

export default {

    components : {},

    created(){
    },
    destroyed(){
        this.reset();
    },
    mounted(){
        window.console.log('UnserPortfolio mounted');
        if (this.$route.hash === '#unser-portfolio') {
            this.init();
        }
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            titleIndex : 0,
            titles : [
                "Healthcare",
                "Digital",
                "OTC",
                "OTX",
                "Rx",
                "DTC",
                "Veterinärmedizin",
                "Medizintechnik",
                "Multisponsoring",
                "Launch Awareness",
                "Patentablauf",
                "Prämarketing",
                "Marketing",
                "Kommunikation",
                "Social Media",
                "Online-Marketing",
                "Postlaunch",
                "Medizintechnik",
                "Multi-Channel-Marketing",
                "Strategie",
                "Konzeption",
                "Text",
                "Unternehmens&shy;kommunikation",
                "Corporate Social Responsibility",
                "Produkt&shy;kommunikation",
                "Beratung",
                "Integrierte Kommunikation",
                "Analysen",
                "Public Relations",
                "Storytelling",
                "Content",
                "SEO",
                "Medical Writing",
                "Blogger",
                "Omni-Channel",
            ],
            titleUpdateInterval : null,
        };
    },

    methods : {
        init(){
            this.titleUpdateInterval = window.setInterval(()=>{
                this.updateTitle();
            }, UPDATE_TITLE_INTERVAL_DELAY_IN_MS);
        },
        reset(){
            window.clearInterval(this.titleUpdateInterval);
        },
        updateTitle(){
            if (this.titleIndex < this.titles.length - 1) {
                this.titleIndex++;
                return;
            }
            this.titleIndex = 0;
        },
    },

    props : [],

    watch : {
        $route(){
            this.reset();
            if (this.$route.hash === '#unser-portfolio') {
                this.init();
            }
        },
    },

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#unser-portfolio {
}

.background {
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    height: calc(100% - 48px);
    background-image: url('/brain/Brain/Brain_1.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.5;
}

.page-title {
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 32px;
    }
    &:before {
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            left: -24px;
            font-size: 32px;
        }
    }
    &:after {
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            right: -24px;
            font-size: 32px;
        }
    }
}

.sub-title {
    margin: 0 32px;
    color: $color-medinmind-red;
    font-family: $font-family-avenir-medium;
    font-size: 16px;
    font-weight: normal;
    @media screen and (max-width: 959px), screen and (max-height: 511px) {
    }
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        margin: 0 24px;
        font-size: 16px;
    }
    br {
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            display: none;
        }
    }
}

.title {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    font-family: $font-family-avenir-medium;
    font-size: 48px;
    font-weight: normal;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 32px;
    }
    @media screen and (max-height: 319px) {
        position: relative;
        top: auto;
        margin-top: 24px;
    }
}

</style>
