<template>
    <div id="start">
        <unser-portfolio></unser-portfolio>
        <!--        <erkunden-sie-uns></erkunden-sie-uns>-->
        <wer-wir-sind></wer-wir-sind>
        <dafuer-stehen-wir></dafuer-stehen-wir>
        <was-wir-fuer-sie-tun-koennen></was-wir-fuer-sie-tun-koennen>
        <statements-unserer-kunden></statements-unserer-kunden>
        <unsere-referenzen></unsere-referenzen>
        <unsere-familie></unsere-familie>
        <unsere-hotline></unsere-hotline>
    </div>
</template>

<script>

import DafuerStehenWir from '../views/DafuerStehenWir';
// import ErkundenSieUns from '../views/ErkundenSieUns';
import StatementsUnsererKunden from '../views/StatementsUnsererKunden';
import UnsereFamilie from '../views/UnsereFamilie';
import UnsereHotline from '../views/UnsereHotline';
import UnsereReferenzen from '../views/UnsereReferenzen';
import UnserPortfolio from '../views/UnserPortfolio';
import WasWirFuerSieTunKoennen from '../views/WasWirFuerSieTunKoennen';
import WerWirSind from '../views/WerWirSind';

const RESIZE_TIMEOUT_DELAY_IN_MS = 100;
const SCROLL_TIMEOUT_DELAY_IN_MS = 200;

export default {

    components : {
        DafuerStehenWir,
        // ErkundenSieUns,
        StatementsUnsererKunden,
        UnsereFamilie,
        UnsereHotline,
        UnsereReferenzen,
        UnserPortfolio,
        WasWirFuerSieTunKoennen,
        WerWirSind,
    },

    created(){
    },
    destroyed(){
        window.removeEventListener('resize', this.onResize);
        this.$el.removeEventListener('scroll', this.onScroll);
    },
    mounted(){
        window.console.log('Start mounted');
        window.addEventListener('resize', this.onResize);
        this.$el.addEventListener('scroll', this.onScroll);
        this.updateBreakpoints();
        this.updateScrollOffsets();
        window.setTimeout(()=>{
            this.updateRoute();
        });
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            metaTags : {
                // TODO
                '#unser-portfolio' : {
                    'meta[name="description"]' : 'PR und Marketing mit Leidenschaft',
                    'meta[property="og:description"]' : 'PR und Marketing mit Leidenschaft',
                    'meta[property="og:title"]' : 'med in mind – Ihre Healthcare Marketing- und PR-Agentur in München',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#unser-portfolio',
                },
                /*
                '#erkunden-sie-uns' : {
                    'meta[name="description"]' : '',
                    'meta[property="og:description"]' : '',
                    'meta[property="og:title"]' : '',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#erkunden-sie-uns',
                },
                */
                '#wer-wir-sind' : {
                    'meta[name="description"]' : 'Ihr Partner für strategische und ideenreiche Kommunikationslösungen im Gesundheitsmarkt',
                    'meta[property="og:description"]' : 'Ihr Partner für strategische und ideenreiche Kommunikationslösungen im Gesundheitsmarkt',
                    'meta[property="og:title"]' : 'med in mind – Wer wir sind',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#wer-wir-sind',
                },
                '#dafuer-stehen-wir' : {
                    'meta[name="description"]' : 'PR und Marketing mit Expertise, Erfahrung und Engagement',
                    'meta[property="og:description"]' : 'PR und Marketing mit Expertise, Erfahrung und Engagement',
                    'meta[property="og:title"]' : 'med in mind – Dafür stehen wir',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#dafuer-stehen-wir',
                },
                '#was-wir-fuer-sie-tun-koennen' : {
                    'meta[name="description"]' : 'Healthcare-Kommunikation – strategisch und kreativ',
                    'meta[property="og:description"]' : 'Healthcare-Kommunikation – strategisch und kreativ',
                    'meta[property="og:title"]' : 'med in mind – Was wir für Sie tun können',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#was-wir-fuer-sie-tun-koennen',
                },
                '#statements-unserer-kunden' : {
                    'meta[name="description"]' : 'Was unsere Kunden über uns sagen',
                    'meta[property="og:description"]' : 'Was unsere Kunden über uns sagen',
                    'meta[property="og:title"]' : 'med in mind – Statements unserer Kunden',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#statements-unserer-kunden',
                },
                '#unsere-referenzen' : {
                    'meta[name="description"]' : 'Unsere Erfahrungen im Gesundheitsmarkt – Sprechen Sie uns an!',
                    'meta[property="og:description"]' : 'Unsere Erfahrungen im Gesundheitsmarkt – Sprechen Sie uns an!',
                    'meta[property="og:title"]' : 'med in mind – Unsere Referenzen',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#unsere-referenzen',
                },
                '#unsere-familie' : {
                    'meta[name="description"]' : 'Bei uns bleiben die Themen in der Familie',
                    'meta[property="og:description"]' : 'Bei uns bleiben die Themen in der Familie',
                    'meta[property="og:title"]' : 'med in mind – Unsere Familie',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#unsere-familie',
                },
                '#unsere-hotline' : {
                    'meta[name="description"]' : 'Kontaktieren Sie uns. Wir freuen uns auf Sie!',
                    'meta[property="og:description"]' : 'Kontaktieren Sie uns. Wir freuen uns auf Sie!',
                    'meta[property="og:title"]' : 'med in mind – Unsere Hotline',
                    'meta[property="og:url"]' : 'https://www.med-in-mind.de/#unsere-hotline',
                },
            },
            resizeTimeout : null,
            scrollTimeout : null,
        };
    },

    methods : {
        getClosestScrollSection(){
            let closestDistance = null;
            let closestSection = null;
            for (let [key, value] of Object.entries(this.$root.states.scrollSections)) {
                const distance = Math.abs(this.$el.scrollTop - value);
                if (closestDistance === null || closestDistance > distance) {
                    closestDistance = distance;
                    closestSection = key;
                }
            }
            window.console.log('getClosestScrollSection', closestSection);
            return closestSection;
        },
        onResize(){
            window.clearTimeout(this.resizeTimeout);
            this.resizeTimeout = window.setTimeout(()=>{
                this.updateBreakpoints();
                this.updateScrollOffsets();
                this.updateScrollPosition();
            }, RESIZE_TIMEOUT_DELAY_IN_MS);
        },
        onScroll(){
            this.$root.states.isScrolling = true;
            window.clearTimeout(this.scrollTimeout);
            this.scrollTimeout = window.setTimeout(()=>{
                this.$root.states.isScrolling = false;
                this.updateRoute();
            }, SCROLL_TIMEOUT_DELAY_IN_MS);
        },
        updateBreakpoints(){
            const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            this.$root.states.breakpoints.maxWidth767 = viewportWidth <= 767;
            this.$root.states.breakpoints.maxWidth959 = viewportWidth <= 959;
            this.$root.states.breakpoints.maxWidth1439 = viewportWidth <= 1439;
            this.$root.states.breakpoints.maxHeight511 = viewportHeight <= 511;
            this.$root.states.breakpoints.maxHeight767 = viewportHeight <= 767;
        },
        updateMetaTags(){
            window.console.log('updateMetaTags', this.$route.hash, this.metaTags[this.$route.hash]);
            if (this.metaTags[this.$route.hash]) {
                for (let [key, value] of Object.entries(this.metaTags[this.$route.hash])) {
                    document.querySelector(key).setAttribute('content', value);
                }
            }
        },
        updateRoute(){
            const closestSection = this.getClosestScrollSection();
            this.$router.push({ name : 'start', hash : closestSection }).catch(()=>{
            });
            this.$root.states.activeScrollSection = this.$route.hash;
        },
        updateScrollOffsets(){
            for (let [key] of Object.entries(this.$root.states.scrollSections)) {
                this.$root.states.scrollSections[key] = document.querySelector(key).offsetTop;
            }
            window.console.log('updateScrollOffsets', this.$root.states.scrollSections);
        },
        updateScrollPosition(){
            const closestSection = this.getClosestScrollSection();
            this.$el.scrollTop = this.$root.states.scrollSections[closestSection];
            // this.$el.dispatchEvent(new Event('scroll'));
        },
    },

    props : [],

    watch : {
        $route(){
            this.updateMetaTags();
        },
    },

}

</script>

<style lang="scss">

@import "../assets/css/variables";

#start {
    height: 100%;
    max-height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: y mandatory;
}

section {
    max-width: 100%;
    overflow: hidden;
    scroll-snap-align: start;
}

</style>
