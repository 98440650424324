let extend = require('extend');

export default {
    letters : [],
    resetLetters(){
        this.timeouts.forEach((timeout)=>{
            window.clearTimeout(timeout);
        });
        this.letters.forEach((letter)=>{
            letter.style.opacity = '0';
        });
    },
    timeouts : [],
    typeLetters(letters, letter, options){
        this.letters = letters;
        const defaults = {
            callBackAfterLastCall : null,
            initialTypeDelay : 500,
            typeDelay : 10,
            invert : false,
        };
        options = extend({}, defaults, options);
        if (letter === undefined || letter === null) {
            letter = options['invert'] ? this.letters[this.letters.length - 1] : this.letters[0];
        }
        this.timeouts.push(window.setTimeout(((letter)=>{
            return function(){
                letter.style.opacity = '1';
            }
        })(letter), options['initialTypeDelay']));
        letter = options['invert'] ? letter.previousSibling : letter.nextSibling;
        if (letter) {
            this.timeouts.push(setTimeout(()=>{
                this.typeLetters(this.letters, letter, options);
            }, options['typeDelay']));
        } else {
            if (typeof options['callBackAfterLastCall'] === 'function') {
                this.timeouts.push(setTimeout(()=>{
                    options['callBackAfterLastCall']();
                }, 800));
            }
        }
    },
}
