<template>
    <section id="statements-unserer-kunden" class="page">
        <div class="background no-gradient"></div>
        <div
            class="articles"
            @mouseover="articleUpdatePause = true"
            @mouseout="articleUpdatePause = false"
        >
            <div class="page-title-container">
                <h1 class="page-title">
                    Statements unserer Kunden
                </h1>
            </div>
            <div class="article-container">
                <article
                    :style="{ 'margin-left' : '-' + (articleIndex * 100) + '%' }"
                >
                    <h2 class="customer">
                        Melanie Koim, Senior Brand Manager,<br class="hidden-on-mobile">
                        MEDICE Arzneimittel Pütter GmbH
                    </h2>
                    <p class="testimonial">
                        „Kompetent, engagiert, ehrlich und vor allem verlässlich – das sind Werte, die ich mit dem Team
                        von med in mind verbinde!
                    </p>
                    <p class="testimonial">
                        Auch wenn unsere Erwartungen hoch sind, werden sie immer noch nach über 10 Jahren
                        kontinuierlicher Zusammenarbeit erfüllt. Für mich ein Glückstreffer, den ich jederzeit
                        weiterempfehlen kann.“
                    </p>
                </article>
                <article>
                    <h2 class="customer">
                        Stephanie Kmitt, Senior PR Managerin,<br class="hidden-on-mobile">
                        Biogen GmbH
                    </h2>
                    <p class="testimonial">
                        „Ich schätze die Kreativität und Zuverlässigkeit von med in mind ebenso wie die Sorgfalt, mit
                        der sie Projekte voranbringen und nachhalten.
                    </p>
                    <p class="testimonial">
                        Es macht Spaß gemeinsam PR Maßnahmen zum Erfolg zu bringen.“
                    </p>
                </article>
            </div>
            <nav class="article-indicators">
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 0 }"
                    @click="toArticleIndex(0)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 1 }"
                    @click="toArticleIndex(1)"
                ></div>
            </nav>
            <nav class="article-chevrons">
                <div
                    class="article-chevron"
                    @click="toPreviousArticle()"
                ></div>
                <div
                    class="article-chevron"
                    @click="toNextArticle()"
                ></div>
            </nav>
        </div>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#statements-unserer-kunden'
        }"></div>
    </section>
</template>

<script>

import Hammer from 'hammerjs';
import createLetters from '../services/createLetters';
import TypeLetters from '../services/typeLetters';

const TYPE_LETTERS_LETTER_INTERVAL_DELAY_IN_MS = 20;
const TYPE_LETTERS_PARAGRAPH_INTERVAL_DELAY_IN_MS = 600;
const UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS = 8000;

export default {

    components : {},

    created(){
    },
    destroyed(){
        this.reset();
        this.articleContainerHammer.off('swipeleft swiperight');
        this.articleContainerHammer.destroy();
    },
    mounted(){
        window.console.log('StatementsUnsererKunden mounted');
        this.articleContainerHammer = new Hammer(this.$el.querySelector('.article-container'));
        this.articleContainerHammer.on('swipeleft', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toNextArticle();
        });
        this.articleContainerHammer.on('swiperight', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toPreviousArticle();
        });
        if (this.$route.hash === '#statements-unserer-kunden') {
            this.init();
        }
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            articleContainerHammer : null,
            articleElement : null,
            articleIndex : 0,
            articleUpdateInterval : null,
            articleUpdatePause : true,
            typeLettersParagraphIndex : 0,
            typeLettersParagraphTimeout : null,
        };
    },

    methods : {
        createArticleLetters(){
            this.articleElement.querySelectorAll('.testimonial').forEach((element)=>{
                createLetters.createLetters(element);
            });
        },
        init(){
            this.initArticleUpdateInterval();
            this.initArticle();
        },
        initArticle(){
            window.clearTimeout(this.typeLettersParagraphTimeout);
            TypeLetters.resetLetters();
            this.articleElement = this.$el.querySelectorAll('article')[this.articleIndex];
            this.createArticleLetters();
            this.articleElement.classList.add('letters');
            this.typeLettersParagraphIndex = 0;
            this.typeArticleLetters();
        },
        initArticleUpdateInterval(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleUpdatePause = false;
            this.articleUpdateInterval = window.setInterval(()=>{
                if (this.articleUpdatePause === true) {
                    return;
                }
                this.updateArticle();
            }, UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS);
        },
        reset(){
            window.clearInterval(this.articleUpdateInterval);
            window.clearTimeout(this.typeLettersParagraphTimeout);
            TypeLetters.resetLetters();
            this.articleIndex = 0;
            this.articleUpdatePause = true;
            this.$el.querySelectorAll('article').forEach((element)=>{
                element.classList.remove('letters');
            });
        },
        toArticleIndex(articleIndex){
            this.initArticleUpdateInterval();
            this.articleIndex = articleIndex;
        },
        toNextArticle(){
            if (this.articleIndex < 1) {
                this.articleIndex++;
                return;
            }
            this.articleIndex = 0;
        },
        toPreviousArticle(){
            if (this.articleIndex > 0) {
                this.articleIndex--;
                return;
            }
            this.articleIndex = 1;
        },
        typeArticleLetters(){
            const typeLettersParagraphElement = this.articleElement.querySelectorAll('.testimonial')[this.typeLettersParagraphIndex];
            if (typeLettersParagraphElement) {
                TypeLetters.typeLetters(typeLettersParagraphElement.querySelectorAll('span'), null, {
                    callBackAfterLastCall : ()=>{
                        window.clearTimeout(this.typeLettersParagraphTimeout);
                        this.typeLettersParagraphTimeout = setTimeout(()=>{
                            this.typeLettersParagraphIndex++;
                            this.typeArticleLetters();
                        }, TYPE_LETTERS_PARAGRAPH_INTERVAL_DELAY_IN_MS);
                    },
                    fadeDelay : TYPE_LETTERS_LETTER_INTERVAL_DELAY_IN_MS,
                });
            }
        },
        updateArticle(){
            this.toNextArticle();
        },
    },

    props : [],

    watch : {
        $route(){
            this.reset();
            if (this.$route.hash === '#statements-unserer-kunden') {
                this.init();
            }
        },
        articleIndex(){
            this.initArticle();
        },
    },

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#statements-unserer-kunden {
    display: flex;
    flex-direction: column;
}

.articles {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: auto;
    background-color: $color-medinmind-white;
    text-align: center;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 85%;
        min-width: 400px;
        height: auto;
        margin: auto;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 75%;
    }
    @media screen and (min-width: 1440px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1920px) and (min-height: 512px) {
    }
}

.article-chevrons {
    .article-chevron {
        position: absolute;
        top: 50%;
        width: (88px / 4);
        height: (176px / 4);
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        transform: translateY(-50%);
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            display: none;
        }
        &:first-child {
            left: 24px;
            background-image: url(../assets/images/ic_chevron_left.svg);
        }
        &:last-child {
            right: 24px;
            background-image: url(../assets/images/ic_chevron_right.svg);
        }
    }
}

.article-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        height: 100%;
    }
    article {
        flex-shrink: 0;
        width: 100%;
        padding: 32px 72px 96px 72px;
        text-align: center;
        transition: margin-left 400ms;
        will-change: margin-left;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            padding: 32px 24px 64px 24px;
        }
        @media screen and (max-width: 511px) {
            padding: 16px 0 64px 0;
        }
        @media screen and (max-height: 511px) {
            height: 100%;
            padding-top: 16px;
        }
    }
}

.article-indicators {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    .article-indicator {
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 12px 0 0;
        background-color: $color-medinmind-white;
        border: 1px solid $color-medinmind-red;
        border-radius: 50%;
        cursor: pointer;
        @media screen and (max-height: 511px) {
            display: none;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background-color: $color-medinmind-red;
        }
    }
}

.customer {
    margin: 0 0 32px 0;
    font-family: $font-family-avenir-medium;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 16px;
    }
    @media screen and (max-width: 511px) {
        margin-bottom: 16px;
    }
    @media screen and (max-height: 511px) {
        margin-bottom: 16px;
        font-size: 14px;
    }
}

.page {
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        padding: 72px 24px 0 24px;
    }
}

.page-title {
    @media screen and (max-width: 959px), screen and (max-height: 767px) {
        font-size: 24px;
    }
    &:before {
        @media screen and (max-width: 959px), screen and (max-height: 767px) {
            left: -16px;
            font-size: 24px;
        }
    }
    &:after {
        @media screen and (max-width: 959px), screen and (max-height: 767px) {
            right: -16px;
            font-size: 24px;
        }
    }
}

.testimonial {
    color: transparent;
    font-family: $font-family-rollerscript-smooth;
    font-size: 20px;
    font-weight: normal;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 16px;
    }
    @media screen and (max-height: 511px) {
        display: inline;
    }
    article.letters & {
        color: $color-medinmind-red;
    }
}

</style>
