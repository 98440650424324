// NOTE: Implementation adapted from https://github.com/davatron5000/Lettering.js

export default {
    createLetters(element, separator = '', className = 'letter letter-', style = 'opacity: 0;', suffix = ''){
        if (element.hasAttribute('aria-label')) {
            element.innerHTML = element.getAttribute('aria-label');
        }
        let textContent = element.textContent;
        let letters = textContent.split(separator);
        let letterMarkup = '';
        if (letters.length) {
            for (let i = 0; i < letters.length; i++) {
                letterMarkup += `<span aria-hidden="true" class="${className + (i + 1)}" style="${style}">${letters[i]}</span>${suffix}`;
            }
            element.setAttribute('aria-label', textContent);
            element.innerHTML = letterMarkup;
        }
    },
}
