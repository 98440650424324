<template>
    <header class="app-header">
        <router-link
            class="logo"
            :to="{ name: 'start', hash: '#unser-portfolio' }"
        >
            <img alt="med in mind" src="../assets/images/med-in-mind-member-of-medictum.svg">
        </router-link>
        <nav
            class="menu-toggle"
            :class="{ 'active' : $root.states.menuIsVisible }"
            @click="$root.states.menuIsVisible = !$root.states.menuIsVisible"
        >
            <div></div>
            <div></div>
            <div></div>
        </nav>
    </header>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
    },
    updated(){
    },

    computed : {},

    data(){
        return {};
    },

    methods : {},

    props : [],

    watch : {},

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

.app-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 48px;
    background-color: $color-medinmind-white;
    box-shadow: 0 0 24px rgba(0, 0, 0, .25);
    z-index: 1;
}

.logo {
    position: absolute;
    left: 20px;
    top: 8px;
    width: 192px;
    img {
        width: 100%;
        height: auto;
    }
    @media screen and (min-width: 768px) and (min-height: 512px) {
        left: 48px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1440px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1920px) and (min-height: 512px) {
    }
}

.menu-toggle {
    position: absolute;
    right: 24px;
    top: 14px;
    cursor: pointer;
    div {
        width: 28px;
        height: 4px;
        margin: 0 0 4px 0;
        background-color: $color-medinmind-red;
        transform-origin: 50% 50%;
        transition: opacity 200ms, transform 200ms;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.active {
        div {
            &:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }
}

</style>
