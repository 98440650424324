<template>
    <section id="wer-wir-sind" class="page">
        <div class="background no-video">
            <video autoplay loop muted>
                <source
                    src="../assets/videos/16524508-hd.mp4"
                    type="video/mp4"
                >
            </video>
        </div>
        <div
            class="articles"
            @mouseover="articleUpdatePause = true"
            @mouseout="articleUpdatePause = false"
        >
            <div class="article-container">
                <article
                    :style="{ 'margin-left' : '-' + (articleIndex * 100) + '%' }"
                >
                    <h1 class="page-title">
                        Wer wir sind
                    </h1>
                    <p class="question">
                        Sie suchen nach dem roten Faden?
                    </p>
                    <h3 class="answer">
                        Wir konzipieren die geeignete Strategie.
                    </h3>
                </article>
                <article>
                    <h1 class="page-title">
                        Wer wir sind
                    </h1>
                    <p class="question">
                        Sie suchen nach einem Partner mit kreativen Ansätzen?
                    </p>
                    <h3 class="answer">
                        Wir entwickeln inspirierende Kommuni&shy;kations&shy;lösungen.
                    </h3>
                </article>
                <article>
                    <h1 class="page-title">
                        Wer wir sind
                    </h1>
                    <p class="question">
                        Sie suchen nach einer Agentur mit wissen&shy;schaftlichem Tiefgang?
                    </p>
                    <h3 class="answer">
                        Wir transportieren komplexe Sach&shy;verhalte ziel&shy;gruppen&shy;gerecht.
                    </h3>
                </article>
                <article>
                    <h1 class="page-title">
                        Wer wir sind
                    </h1>
                    <p class="question">
                        <img
                            alt="med in mind"
                            class="logo"
                            src="../assets/images/med-in-mind.svg"
                        />
                        <img
                            alt="Leidenschaft macht den Unterschied"
                            class="claim"
                            src="../assets/images/leidenschaft-macht-den-unterschied.svg"
                        />
                    </p>
                    <h2 class="bottom-line">
                        Ihr Partner für strategische und ideenreiche Kommuni&shy;kations&shy;lösungen im Gesundheits&shy;markt.
                    </h2>
                </article>
            </div>
            <nav class="article-indicators">
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 0 }"
                    @click="toArticleIndex(0)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 1 }"
                    @click="toArticleIndex(1)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 2 }"
                    @click="toArticleIndex(2)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 3 }"
                    @click="toArticleIndex(3)"
                ></div>
            </nav>
            <nav class="article-chevrons">
                <div
                    class="article-chevron"
                    @click="toPreviousArticle()"
                ></div>
                <div
                    class="article-chevron"
                    @click="toNextArticle()"
                ></div>
            </nav>
        </div>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#wer-wir-sind'
        }"></div>
    </section>
</template>

<script>

import Hammer from 'hammerjs';

const UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS = 5000;

export default {

    components : {},

    created(){
    },
    destroyed(){
        this.reset();
        this.articleContainerHammer.off('swipeleft swiperight');
        this.articleContainerHammer.destroy();
    },
    mounted(){
        window.console.log('WerWirSind mounted');
        this.articleContainerHammer = new Hammer(this.$el.querySelector('.article-container'));
        this.articleContainerHammer.on('swipeleft', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toNextArticle();
        });
        this.articleContainerHammer.on('swiperight', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toPreviousArticle();
        });
        if (this.$route.hash === '#wer-wir-sind') {
            this.init();
        }
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            articleContainerHammer : null,
            articleIndex : 0,
            articleUpdateInterval : null,
            articleUpdatePause : true,
        };
    },

    methods : {
        init(){
            this.initArticleUpdateInterval();
        },
        initArticleUpdateInterval(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleUpdatePause = false;
            this.articleUpdateInterval = window.setInterval(()=>{
                if (this.articleUpdatePause === true) {
                    return;
                }
                this.updateArticle();
            }, UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS);
        },
        reset(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleIndex = 0;
            this.articleUpdatePause = true;
        },
        toArticleIndex(articleIndex){
            this.initArticleUpdateInterval();
            this.articleIndex = articleIndex;
        },
        toNextArticle(){
            if (this.articleIndex < 3) {
                this.articleIndex++;
                return;
            }
            this.articleIndex = 0;
        },
        toPreviousArticle(){
            if (this.articleIndex > 0) {
                this.articleIndex--;
                return;
            }
            this.articleIndex = 3;
        },
        updateArticle(){
            this.toNextArticle();
        },
    },

    props : [],

    watch : {
        $route(){
            this.reset();
            if (this.$route.hash === '#wer-wir-sind') {
                this.init();
            }
        },
    },

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#wer-wir-sind {
    display: flex;
    flex-direction: column;
}

.answer {
    margin: 0;
    color: $color-medinmind-red;
    font-family: $font-family-avenir-black;
    font-size: 48px;
    font-weight: normal;
    @media screen and (max-width: 767px), screen and (max-height: 767px) {
        font-size: 20px;
    }
}

.articles {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: auto;
    background-color: $color-medinmind-white;
    @media screen and (min-width: 768px) and (min-height: 768px) {
        width: 85%;
        min-width: 400px;
        height: auto;
        margin: auto;
    }
    @media screen and (min-width: 960px) and (min-height: 768px) {
        width: 75%;
    }
    @media screen and (min-width: 1280px) and (min-height: 768px) {
    }
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        width: 50%;
    }
    @media screen and (min-width: 1920px) and (min-height: 768px) {
    }
}

.article-chevrons {
    .article-chevron {
        position: absolute;
        top: 50%;
        width: (88px / 4);
        height: (176px / 4);
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
        transform: translateY(-50%);
        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            display: none;
        }
        &:first-child {
            left: 24px;
            background-image: url(../assets/images/ic_chevron_left.svg);
        }
        &:last-child {
            right: 24px;
            background-image: url(../assets/images/ic_chevron_right.svg);
        }
    }
}

.article-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px), screen and (max-height: 767px) {
        height: 100%;
    }
    article {
        flex-shrink: 0;
        width: 100%;
        padding: 32px 72px 96px 72px;
        text-align: center;
        transition: margin-left 400ms;
        will-change: margin-left;
        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            padding: 32px 24px 64px 24px;
        }
        @media screen and (max-height: 511px) {
            height: 100%;
        }
    }
}

.article-indicators {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    .article-indicator {
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 12px 0 0;
        background-color: $color-medinmind-white;
        border: 1px solid $color-medinmind-red;
        border-radius: 50%;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background-color: $color-medinmind-red;
        }
    }
}

.claim {
    width: 80%;
    height: auto;
    margin: 0 auto;
    max-width: 800px;
}

.background {
    &.no-video {
        background-image: url(../assets/images/16524508-hd.jpg);
    }
}

.bottom-line {
    font-family: $font-family-avenir-black;
    font-size: 24px;
    font-weight: normal;
    @media screen and (max-width: 767px), screen and (max-height: 767px) {
        font-size: 20px;
    }
}

.logo {
    margin: 0 auto 20px auto;
}

.page {
    padding: 96px 72px 72px 72px;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 48px 0 0 0;
    }
}

.question {
    margin: 48px 0;
    font-size: 24px;
    font-weight: normal;
    @media screen and (max-width: 767px), screen and (max-height: 767px) {
        margin: 24px 0;
        font-size: 20px;
    }
}

</style>
