<template>
    <section id="unsere-hotline" class="page">
        <gmap-map
            :center="center"
            class="map"
            :options="{
                    clickableIcons: false,
                    disableDefaultUi: false,
                    draggable: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    scrollwheel: false,
                    streetViewControl: false,
                    styles: [{
                        stylers:[{ saturation: -100}]
                    }],
                    zoomControl: false,
                }"
            ref="map"
            @zoom_changed="onZoomChanged()"
            :zoom="initialZoomLevel"
        >
            <gmap-custom-marker
                :marker="marker"
            >
                <div class="page-title-container">
                    <h1 class="page-title">
                        Unsere Hotline
                    </h1>
                </div>
                <div class="map-marker-contact-container">
                    <div
                        class="map-marker-contact-toggle"
                        :class="{ 'active' : formIsActive }"
                        @click="formIsActive = !formIsActive"
                    >
                        <span>
                            Kontaktformular
                        </span>
                    </div>
                </div>
                <div class="map-marker">
                    <div class="map-marker-container">
                        <div class="map-marker-logo-container">
                            <img
                                alt="med in mind"
                                class="logo"
                                src="../assets/images/med-in-mind.svg"
                            />
                        </div>
                        <div class="map-marker-address-phone-email-container">
                            <div class="map-marker-address-container">
                                <address>
                                    med in mind GmbH <br/>
                                    Dachauer Str. 38<br/>
                                    80335 München
                                </address>
                            </div>
                            <div class="map-marker-phone-email-container">
                                <a href="tel:+491775989622">
                                    <img
                                        alt="Telefon"
                                        src="../assets/images/ic_phone.svg"
                                    />
                                    <span>
                                        +49 177 5989622
                                    </span>
                                </a>
                                <a href="mailto:info@med-in-mind.de">
                                    <img
                                        alt="E-Mail"
                                        src="../assets/images/ic_email.svg"
                                    />
                                    <span>
                                        info@med-in-mind.de
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="map-marker-social-links-container">
                            <h3>
                                Vernetzen Sie sich mit uns!
                            </h3>
                            <div class="social-links">
                                <a
                                    class="social-link"
                                    href="https://www.facebook.com/medictum.de"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <img
                                        alt="Facebook"
                                        src="../assets/images/ic_facebook.svg"
                                    >
                                </a>
                                <a
                                    class="social-link"
                                    href="https://www.instagram.com/inside_medictum/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <img
                                        alt="Instagram"
                                        src="../assets/images/ic_instagram.svg"
                                    >
                                </a>
                                <a
                                    class="social-link"
                                    href="https://www.linkedin.com/company/medictum/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <img
                                        alt="LinkedIn"
                                        src="../assets/images/ic_linkedin.svg"
                                    >
                                </a>
                                <a
                                    class="social-link"
                                    href="https://www.linkedin.com/company/medictum/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <img
                                        alt="Twitter"
                                        src="../assets/images/ic_twitter.svg"
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </gmap-custom-marker>
        </gmap-map>
        <div class="map-control">
            <button
                class="map-control-button map-control-button-zoom-in"
                @click="zoomIn()"
            ></button>
            <button
                class="map-control-button map-control-button-zoom-out"
                @click="zoomOut()"
            ></button>
        </div>
        <footer
            v-if="$route.hash === '#unsere-hotline'"
            class="footer"
        >
            <div class="footer-nav-container">
                <nav class="footer-nav">
                    ©2021 med in mind GmbH
                    <span class="hidden-on-mobile">|</span>
                    <br class="hidden-on-desktop">
                    <router-link :to="{ name: 'impressum' }">
                        Impressum
                    </router-link>
                    <span>|</span>
                    <router-link :to="{ name: 'datenschutz' }">
                        Datenschutz
                    </router-link>
                    <span>|</span>
                    <a href="javascript: Cookiebot.renew()">
                        Cookies
                    </a>
                    <br class="hidden-on-desktop">
                </nav>
            </div>
            <div class="footer-logo-container">
                <div class="logo">
                    <img
                        alt="med in mind"
                        src="../assets/images/med-in-mind.svg"
                    >
                    <img
                        alt="Leidenschaft macht den Unterschied"
                        src="../assets/images/leidenschaft-macht-den-unterschied.svg"
                    >
                </div>
            </div>
        </footer>
        <aside
            class="popup"
            :class="{ 'active' : formIsActive }"
        >
            <div
                class="popup-action"
                @click.stop="formIsActive = false"
            ></div>
            <div class="popup-form">
                <div class="popup-form-container">
                    <form
                        v-if="formIsSubmitted === false"
                        @submit.prevent="onFormSubmit()"
                    >
                        <p class="form-group">
                            <label>
                                Name*:
                                <input
                                    v-model="form.name"
                                    class="form-control"
                                    @input="validateForm()"
                                    name="contactName"
                                    type="text"
                                    value=""
                                />
                            </label>
                            <span
                                v-if="formValidationErrors.name"
                                class="form-error"
                            >
                                Bitte geben Sie Ihren Namen ein
                            </span>
                        </p>
                        <p class="form-group">
                            <label>
                                Ihre E-Mail*:
                                <input
                                    v-model="form.email"
                                    class="form-control"
                                    @input="validateForm()"
                                    name="contactEmail"
                                    type="email"
                                    value=""
                                />
                            </label>
                            <span
                                v-if="formValidationErrors.email"
                                class="form-error"
                            >
                                Bitte geben Sie Ihre E-Mail Addresse ein
                            </span>
                        </p>
                        <p class="form-group">
                            <label>
                                Ihre Nachricht*:
                                <textarea
                                    v-model="form.message"
                                    class="form-control"
                                    @input="validateForm()"
                                    name="contactMessage"
                                    rows="4"
                                ></textarea>
                            </label>
                            <span
                                v-if="formValidationErrors.message"
                                class="form-error"
                            >
                                Bitte geben Sie Ihre Nachricht ein
                            </span>
                        </p>
                        <p class="form-group">
                            <label
                                class="form-checkbox-label"
                                @click="togglePrivacyStatementConfirmation()"
                            >
                                <span class="form-checkbox">
                                    <span
                                        v-if="form.checkbox"
                                        class="form-checkbox-check"
                                    ></span>
                                </span>
                                <span class="form-checkbox-description">
                                    Ich habe die
                                    <router-link to="/privacy-policy">Datenschutzerklärung</router-link>
                                    zur Kenntnis genommen und erkläre mich mit deren Geltung und der Verarbeitung meiner
                                    personenbezogenen Daten einverstanden.*
                                </span>
                            </label>
                            <span
                                v-if="formValidationErrors.checkbox"
                                class="form-error"
                            >
                                Bitte akzeptieren Sie die Datenschutzerklärung
                            </span>
                        </p>
                        <p class="form-footer">
                            * Pflichtfeld
                        </p>
                        <button
                            class="button button-white"
                            type="submit"
                        >
                            Abschicken
                        </button>
                    </form>
                    <p
                        v-if="formIsSubmitted"
                        class="form-submit-message"
                    >
                        Vielen Dank für Ihre Anfrage. Ihre Nachricht wurde erfolgreich an uns weitergeleitet. Gerne
                        melden wir uns in Kürze bei Ihnen. Ihr Team von med in mind.
                    </p>
                </div>
            </div>
        </aside>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#unsere-hotline'
        }"></div>
    </section>
</template>

<script>

import GmapCustomMarker from 'vue2-gmap-custom-marker';

const FORM_RESET_AFTER_SUBMIT_TIMEOUT_DELAY_IN_MS = 10000;
const FORM_SUBMIT_URL = '/rest-api/contact';
// const FORM_SUBMIT_URL = 'https://localhost:24321/rest-api/contact'; // TODO
const RESIZE_TIMEOUT_DELAY_IN_MS = 100;

export default {

    components : {
        'gmap-custom-marker' : GmapCustomMarker,
    },

    created(){
    },
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    },
    mounted(){
        window.console.log('UnsereHotline mounted');
        window.addEventListener('resize', this.onResize);
        this.centerMap();
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            center : {
                lat : 48.1462,
                lng : 11.5599,
            },
            form : {
                checkbox : '',
                email : '',
                message : '',
                name : '',
            },
            formIsActive : false,
            formIsSubmitted : false,
            formResetAfterSubmitTimeout : null,
            formValidationErrors : {
                checkbox : false,
                email : false,
                message : false,
                name : false,
            },
            initialZoomLevel : 15,
            marker : {
                lat : 48.1456,
                lng : 11.5599,
            },
            panOffset : [0, 0],
            resizeTimeout : null,
            zoomLevel : 15,
            zoomMax : 15,
            zoomMin : 3,
        };
    },

    methods : {
        centerMap(){
            this.$refs.map.$mapPromise.then((map)=>{
                if (this.$root.states.breakpoints.maxHeight767) {
                    this.panOffset = [0, 288 - 72]; // TODO ...
                } else {
                    this.panOffset = [0, 560 - 72]; // TODO ...
                }
                map.panTo(this.center);
                map.panBy(this.panOffset[0] / -2, this.panOffset[1] / -2);
            });
        },
        onFormSubmit(){
            window.clearTimeout(this.formResetAfterSubmitTimeout);
            if (this.validateForm() === false) {
                return;
            }
            const params = {
                name : this.form.name,
                email : this.form.email,
                message : this.form.message,
            };
            this.resetFormValidation();
            this.$http.post(FORM_SUBMIT_URL, params)
                .then(()=>{
                    this.formIsSubmitted = true;
                    this.resetForm();
                    window.clearTimeout(this.formResetAfterSubmitTimeout);
                    this.formResetAfterSubmitTimeout = window.setTimeout(()=>{
                        this.formIsActive = false;
                        this.formIsSubmitted = false;
                    }, FORM_RESET_AFTER_SUBMIT_TIMEOUT_DELAY_IN_MS);
                }).catch((e)=>{
                window.console.log(e)
            });
        },
        onResize(){
            window.clearTimeout(this.resizeTimeout);
            this.resizeTimeout = window.setTimeout(()=>{
                this.centerMap();
            }, RESIZE_TIMEOUT_DELAY_IN_MS);
        },
        onZoomChanged(){
            this.centerMap();
        },
        resetForm(){
            Object.keys(this.form).forEach((key)=>{
                this.form[key] = '';
            });
        },
        resetFormValidation(){
            this.formValidationErrors = {
                checkbox : false,
                email : false,
                message : false,
                name : false,
            };
        },
        togglePrivacyStatementConfirmation(){
            this.form.checkbox = !this.form.checkbox;
            this.formValidationErrors.checkbox = !this.form.checkbox;
            // if (this.form.checkbox) {
            //     this.formValidationErrors.checkbox = false;
            // }
        },
        validateForm(){
            // if (!this.form.email && !this.form.message && !this.form.name) {
            //     this.formValidationErrors = {
            //         checkbox : !this.form.checkbox,
            //         email : true,
            //         message : true,
            //         name : true,
            //     };
            //     return false;
            // }
            if (!this.form.name || !this.form.email || !this.form.message || !this.form.checkbox) {
                this.formValidationErrors.name = !this.form.name;
                this.formValidationErrors.email = !this.form.email;
                this.formValidationErrors.message = !this.form.message;
                this.formValidationErrors.checkbox = !this.form.checkbox;
                return false;
            }
            return true;
        },
        zoomIn(){
            if (this.zoomLevel < this.zoomMax) {
                this.zoomLevel++;
            }
            this.zoomTo(this.zoomLevel, true);
        },
        zoomOut(){
            if (this.zoomLevel > this.zoomMin) {
                this.zoomLevel--;
            }
            this.zoomTo(this.zoomLevel, true);
        },
        zoomTo(zoomLevel){
            this.zoomLevel = zoomLevel;
            this.$refs.map.$mapObject.setZoom(this.zoomLevel);
        },
    },

    props : [],

    watch : {},

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#unsere-hotline {
}

.background {
    &:after {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    }
}

.footer {
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    background-color: $color-medinmind-white;
    box-shadow: 0 0 24px rgba(0, 0, 0, .25);
    z-index: 1;
    @media screen and (max-height: 511px) {
        display: none;
    }
}

.footer-logo-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.footer-nav {
    margin: auto 0;
    color: $color-medinmind-black;
    font-family: $font-family-avenir-book;
    font-size: 16px;
    font-weight: normal;
    @media screen and (max-width: 767px) {
        font-size: 14px;
        text-align: center;
    }
    a {
        color: $color-medinmind-black;
        text-decoration: none;
        &:hover {
            color: $color-medinmind-red;
        }
    }
    span {
        margin: 0 8px;
        @media screen and (max-width: 767px) {
            margin: 0 4px;
        }
    }
}

.footer-nav-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 0;
    @media screen and (max-width: 767px) {
        margin: 0 auto;
    }
}

.form-checkbox {
    flex-shrink: 0;
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 8px 0 0;
    cursor: pointer;
    border: 1px solid $color-medinmind-white;
    .form-checkbox-check {
        display: block;
        position: absolute;
        width: 6px;
        height: 14px;
        margin: 0 12px 0 6px;
        border-right: 2px solid $color-medinmind-white;
        border-bottom: 2px solid $color-medinmind-white;
        transform: rotate(45deg);
    }
}

.form-checkbox-description {
    a {
        color: $color-medinmind-white;
    }
}

.form-checkbox-label {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.form-error {
    display: block;
    margin: 8px 0 0 0;
    color: $color-medinmind-white;
    font-size: 14px;
}

.form-footer {
    color: $color-medinmind-white;
    font-size: 14px;
}

.form-group {
    display: block;
    margin: 24px 0;
    label {
        color: $color-medinmind-white;
        font-size: 16px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 14px;
        }
    }
    .form-control {
        margin: 12px 0 0 0;
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: 8px 12px;
    border: 0 none;
    color: $color-medinmind-black;
    font-size: 16px;
    outline: none;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 14px;
    }
}

.form-submit-message {
    padding: 48px 24px;
    color: $color-medinmind-white;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        padding: 48px;
    }
}

.logo {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    img {
        margin: 0 0 0 auto;
        height: auto;
        &:first-child {
            width: 96px;
        }
        &:last-child {
            width: 192px;
        }
    }
}

.map {
    height: 100%;
    .vue-map {
    }
}

.map-control {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 24px;
    bottom: 72px;
    @media screen and (max-height: 511px) {
        bottom: 24px;
    }
}

.map-control-button {
    display: block;
    width: 32px;
    height: 32px;
    margin-bottom: 1px;
    background-color: $color-medinmind-red;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    border: 0 none;
    cursor: pointer;
    outline: none;
    &.map-control-button-zoom-in {
        background-image: url('../assets/images/ic_plus.svg');
    }
    &.map-control-button-zoom-out {
        background-image: url('../assets/images/ic_minus.svg');
    }
}

.map-marker {
    position: relative;
    padding: 40px 40px 32px 40px;
    background-color: $color-medinmind-white;
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-36px);
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        padding: 24px 24px 16px 24px;
    }
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 80px;
        height: 80px;
        background-color: $color-medinmind-white;
        transform: translate(-50%, 25%) rotate(-45deg);
        transform-origin: 50% 50%;
    }
}

.map-marker-contact-container {
    position: relative;
    margin: 0 0 (40px + 36px) 0;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        margin: 0 0 (0 + 36px) 0;
    }
}

.map-marker-contact-toggle {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 0 0 20px;
    background-color: $color-medinmind-red;
    cursor: pointer;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        height: 32px;
    }
    &:after {
        content: '';
        position: absolute;
        right: 16px;
        top: 50%;
        width: 12px;
        height: 12px;
        background-image: url(../assets/images/ic_plus.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
    }
    &.active {
        &:after {
            background-image: url(../assets/images/ic_minus.svg);
        }
    }
    > span {
        display: block;
        margin: auto 0;
        color: $color-medinmind-white;
        font-family: $font-family-avenir-black;
        font-size: 16px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            font-size: 14px;
        }
    }
}

.map-marker-container {
    display: flex;
    flex-direction: column;
    position: relative;
    .map-marker-address-phone-email-container {
        display: flex;
        flex-direction: row;
        padding: 0 0 20px 0;
        border-bottom: 1px solid $color-medinmind-grey;
        @media screen and (max-width: 511px) {
            flex-direction: column;
        }
        @media screen and (max-height: 511px) {
            border-bottom: 0 none;
        }
        .map-marker-address-container {
            margin: 0 auto 0 0;
            padding: 0 48px 0 0;
            address {
                font-size: 16px;
                line-height: 1.4;
                @media screen and (max-width: 767px), screen and (max-height: 511px) {
                    font-size: 14px;
                }
                @media screen and (max-width: 511px) {
                    margin-bottom: 12px;
                }
            }
        }
        .map-marker-phone-email-container {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 auto;
            padding: 0 0 0 48px;
            @media screen and (max-width: 511px) {
                margin: 0;
                padding: 0;
            }
            a {
                display: flex;
                flex-direction: row;
                color: $color-medinmind-black;
                font-size: 16px;
                line-height: 1.4;
                text-decoration: none;
                @media screen and (max-width: 767px), screen and (max-height: 511px) {
                    font-size: 14px;
                }
                &:first-child {
                    margin: 0 0 auto 0;
                    @media screen and (max-width: 511px) {
                        margin: 0;
                    }
                }
                &:last-child {
                    margin: auto 0 0 0;
                    @media screen and (max-width: 511px) {
                        margin: 0;
                    }
                }
                img {
                    width: 20px;
                    height: auto;
                }
                span {
                    display: block;
                    margin: auto auto auto 12px;
                }
            }
        }
    }
    .map-marker-logo-container {
        display: flex;
        flex-direction: row;
        padding: 0 0 32px 0;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            padding-bottom: 24px;
        }
        img {
            width: 200px;
            height: auto;
            margin: 0 auto;
            @media screen and (max-width: 767px), screen and (max-height: 511px) {
                width: 144px;
            }
        }
    }
    .map-marker-social-links-container {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;
        @media screen and (max-height: 511px) {
            display: none;
        }
        h3 {
            margin: 0 0 12px 0;
            text-align: center;
            font-family: $font-family-avenir-black;
            font-size: 20px;
            font-weight: normal;
            @media screen and (max-width: 767px), screen and (max-height: 511px) {
                margin: 0 0 4px 0;
                font-size: 14px;
            }
        }
    }
}

.page {
    padding: 48px 0 0 0;
}

.popup {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    max-height: calc(100% - 48px);
    background-color: $color-medinmind-red;
    box-shadow: 0 0 48px rgba(0, 0, 0, .25);
    z-index: 2;
    overflow: auto;
    transform: translateX(-50%) translateY(-50%);
    @media screen and (min-width: 768px) and (min-height: 512px) {
        max-width: 640px;
    }
    &.active {
        display: block;
    }
}

.popup-action {
    display: block;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-image: url(../assets/images/ic_x.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}

.popup-form {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $color-medinmind-red;
    overflow: hidden;
}

.popup-form-container {
    padding: 0 20px 20px 20px;
}

.page-title-container {
    text-align: center;
    padding: 0 0 40px 0;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        display: none;
    }
}

.social-links {
    display: flex;
    flex-direction: row;
    @media screen and (max-height: 511px) {
        display: none;
    }
    .social-link {
        display: block;
        margin: 0 4px 0 0;
        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-right: auto;
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

</style>
