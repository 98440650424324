<template>
    <aside
        v-if="$root.states.menuIsVisible"
        class="app-menu"
    >
        <div class="app-menu-background"></div>
        <nav class="app-menu-nav">
            <router-link
                :class="{ active : $route.hash === '#unser-portfolio' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#unser-portfolio' }"
            >
                Home
            </router-link>
            <!--
            <router-link
                :class="{ active : $route.hash === '#erkunden-sie-uns' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#erkunden-sie-uns' }"
            >
                Erkunden Sie uns
            </router-link>
            //-->
            <router-link
                :class="{ active : $route.hash === '#wer-wir-sind' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#wer-wir-sind' }"
            >
                Wer wir sind
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#dafuer-stehen-wir' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#dafuer-stehen-wir' }"
            >
                Dafür stehen wir
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#was-wir-fuer-sie-tun-koennen' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#was-wir-fuer-sie-tun-koennen' }"
            >
                Was wir für Sie tun können
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#statements-unserer-kunden' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#statements-unserer-kunden' }"
            >
                Statements unserer Kunden
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#unsere-referenzen' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#unsere-referenzen' }"
            >
                Unsere Referenzen
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#unsere-familie' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#unsere-familie' }"
            >
                Unsere Familie
            </router-link>
            <router-link
                :class="{ active : $route.hash === '#unsere-hotline' }"
                @click.native="$root.states.menuIsVisible = false"
                :to="{ name: 'start', hash: '#unsere-hotline' }"
            >
                Unsere Hotline
            </router-link>
            <footer class="footer">
                <div class="footer-nav-container">
                    <div class="footer-nav">
                        <router-link
                            @click.native="$root.states.menuIsVisible = false"
                            :to="{ name: 'impressum' }"
                        >
                            Impressum
                        </router-link>
                        <router-link
                            @click.native="$root.states.menuIsVisible = false"
                            :to="{ name: 'datenschutz' }"
                        >
                            Datenschutz
                        </router-link>
                        <a href="javascript: Cookiebot.renew()">
                            Cookies
                        </a>
                    </div>
                </div>
                <div class="footer-logo-container">
                    <div class="logo">
                        <img
                            alt="med in mind"
                            src="../assets/images/med-in-mind.svg"
                        >
                        <img
                            alt="Leidenschaft macht den Unterschied"
                            src="../assets/images/leidenschaft-macht-den-unterschied.svg"
                        >
                    </div>
                </div>
            </footer>
        </nav>
    </aside>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
    },
    updated(){
    },

    computed : {},

    data(){
        return {};
    },

    methods : {},

    props : [],

    watch : {},

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

.app-menu {
}

.app-menu-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    z-index: 1;
}

.app-menu-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 48px;
    width: 100%;
    height: calc(100% - 48px);
    max-height: calc(100% - 48px);
    padding: 48px 0;
    background-color: $color-medinmind-red;
    box-shadow: 0 0 24px rgba(0, 0, 0, .25);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 75%;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 50%;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1440px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1920px) and (min-height: 512px) {
        width: 720px;
    }
    > a {
        position: relative;
        display: block;
        padding: 10px 40px 10px 80px;
        color: $color-medinmind-white;
        font-size: 16px;
        font-family: $font-family-avenir-medium;
        font-weight: normal;
        text-decoration: none;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            padding: 20px * $factor-768 72px * $factor-768 20px * $factor-768 144px * $factor-768;
            font-size: 32px * $factor-768;
        }
        @media screen and (min-width: 960px) and (min-height: 512px) {
            padding: 20px * $factor-960 72px * $factor-960 20px * $factor-960 144px * $factor-960;
            font-size: 32px * $factor-768;
        }
        @media screen and (min-width: 1280px) and (min-height: 512px) {
        }
        @media screen and (min-width: 1440px) and (min-height: 512px) {
        }
        @media screen and (min-width: 1920px) and (min-height: 512px) {
            padding: 20px 72px 20px 144px;
            font-size: 32px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 30px;
            top: 50%;
            width: 30px;
            height: 30px;
            background-image: url(../assets/images/ic_brain.png);
            background-repeat: no-repeat;
            background-size: 100%;
            opacity: 0;
            transform: translateY(-50%);
            transition: opacity 200ms;
            @media screen and (min-width: 768px) and (min-height: 512px) {
                left: 64px * $factor-768;
                width: 40px * $factor-768;
                height: 40px * $factor-768;
            }
            @media screen and (min-width: 960px) and (min-height: 512px) {
                left: 64px * $factor-960;
                width: 40px * $factor-960;
                height: 40px * $factor-960;
            }
            @media screen and (min-width: 1280px) and (min-height: 512px) {
            }
            @media screen and (min-width: 1440px) and (min-height: 512px) {
            }
            @media screen and (min-width: 1920px) and (min-height: 512px) {
                left: 64px;
                width: 40px;
                height: 40px;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
        &:last-of-type {
            margin-bottom: 48px;
        }
        &.active {
            font-family: $font-family-avenir-black;
            font-weight: normal;
            &:before {
                opacity: 1;
            }
        }
    }
}

.footer {
    display: flex;
    flex-direction: row;
    height: 48px;
    min-height: 48px;
    margin: auto 0 -48px 0;
    background-color: $color-medinmind-white;
}

.footer-logo-container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
    padding: 0 24px 0 0;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.footer-nav {
    display: flex;
    flex-direction: row;
    margin: auto 0;
    padding: 0 0 0 24px;
    a {
        display: inline-block;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-medium;
        font-size: 16px;
        font-weight: normal;
        text-decoration: none;
        margin: 0 16px 0 0;
        &:last-child {
            margin-right: 0;
        }
    }
}

.footer-nav-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 0;
}

.logo {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    img {
        margin: 0 0 0 auto;
        height: auto;
        &:first-child {
            width: 96px;
        }
        &:last-child {
            width: 192px;
        }
    }
}

</style>
