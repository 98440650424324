<template>
    <section id="unsere-referenzen" class="page">
        <div class="background no-video">
            <video autoplay loop muted>
                <source
                    src="../assets/videos/1019250754-hd.mp4"
                    type="video/mp4"
                >
            </video>
        </div>
        <div class="page-title-container">
            <h1 class="page-title">
                Unsere Referenzen
            </h1>
        </div>
        <ul class="references">
            <li>
                3M
            </li>
            <li>
                Ardeypharm
            </li>
            <li>
                Aristo Pharma
            </li>
            <li>
                Astellas
            </li>
            <li>
                Bencard Allergie
            </li>
            <li>
                Berlin Chemie Menarini
            </li>
            <li>
                Biogen
            </li>
            <li>
                Boehringer Ingelheim
            </li>
            <li>
                Dr. Pfleger
            </li>
            <li>
                Eisai
            </li>
            <li>
                Heine Optotechnik
            </li>
            <li>
                Hexal
            </li>
            <li>
                Karo Pharma
            </li>
            <li>
                Leo Pharma
            </li>
            <li>
                Medice
            </li>
            <li>
                MSD Tiergesundheit
            </li>
            <li>
                OmniVision
            </li>
            <li>
                Pari
            </li>
            <li>
                Sobi
            </li>
            <li>
                Stada
            </li>
            <li>
                Trommsdorff
            </li>
        </ul>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#unsere-referenzen'
        }"></div>
    </section>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
        window.console.log('UnsereReferenzen mounted');
    },
    updated(){
    },

    computed : {},

    data(){
        return {};
    },

    methods : {},

    props : [],

    watch : {},

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#unsere-referenzen {
}

.background {
    &.no-video {
        background-image: url(../assets/images/1019250754-hd.jpg);
    }
}

.page {
    padding: 96px 72px 48px 72px;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        padding: 144px 96px 96px 96px;
    }
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 80px 32px 32px 32px;
    }
}

.references {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    margin: 48px 0 0 0;
    padding: 0;
    list-style: none;
    @media screen and (max-height: 511px) {
        margin-top: 24px;
    }
    li {
        margin: 0 24px 12px 0;
        padding: 0;
        color: $color-medinmind-black;
        font-family: $font-family-avenir-medium;
        font-size: 16px;
        font-weight: normal;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            font-size: 24px;
        }
        @media screen and (max-height: 511px) {
            margin-bottom: 4px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

</style>
