<template>
    <div id="app">
        <router-view/>
        <nav class="page-indicators">
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#unser-portfolio' }"
            ></router-link>
            <!--
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#erkunden-sie-uns' }"
            ></router-link>
            //-->
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#wer-wir-sind' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#dafuer-stehen-wir' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#was-wir-fuer-sie-tun-koennen' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#statements-unserer-kunden' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#unsere-referenzen' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#unsere-familie' }"
            ></router-link>
            <router-link
                active-class="active"
                :to="{ name: 'start', hash: '#unsere-hotline' }"
            ></router-link>
        </nav>
        <div
            class="scroll-indicator"
            :class="{
                'active' : isLastScrollSection === false && isStartRoute && $root.states.isScrolling === false && $root.states.menuIsVisible === false
            }"
            @click="toNextSection()"
        ></div>
        <app-menu></app-menu>
        <app-header></app-header>
    </div>
</template>

<script>

import AppHeader from './components/AppHeader';
import AppMenu from './components/AppMenu';

export default {

    components : {
        AppMenu,
        AppHeader,
    },

    created(){
    },
    destroyed(){
    },
    mounted(){
        this.initRoute();
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            isLastScrollSection : false,
            isStartRoute : false,
        };
    },

    methods : {
        toNextSection(){
            const scrollSections = Object.keys(this.$root.states.scrollSections);
            const nextScrollSection = scrollSections[scrollSections.indexOf(this.$route.hash) + 1];
            window.console.log('toNextSection', nextScrollSection);
            if (nextScrollSection) {
                this.$router.push({ name : 'start', hash : nextScrollSection });
            }
        },
        initRoute(){

            this.isStartRoute = this.$route.name === 'start';

            // NOTE: Unfortunately VueRouter scrollBehavior is not called on initial load due to a bug. Thus it is required
            // to do it manually, so that the page is being scrolled to the corresponding section.
            //
            // See: https://github.com/vuejs/vue-router/issues/2358

            if (this.$route.hash) {
                const pageElement = document.querySelector(this.$route.hash);
                return document.querySelector('#start').scrollTo({
                    behavior : 'smooth',
                    top : pageElement ? pageElement.offsetTop : 0,
                });
            }

        },
    },

    props : [],

    watch : {
        $route(){
            const scrollSections = Object.keys(this.$root.states.scrollSections);
            this.isLastScrollSection = scrollSections.indexOf(this.$route.hash) === scrollSections.length - 1;
            this.isStartRoute = this.$route.name === 'start';
        },
    },

}

</script>

<style lang="scss">

@import "assets/css/fonts";
@import "assets/css/variables";

input[type='checkbox'].CybotCookiebotDialogBodyLevelButton + label {
    background-image: url(assets/images/Checkbox-inactive-14x14px.svg) !important;
}

input[type='checkbox'].CybotCookiebotDialogBodyLevelButton:checked + label {
    background-image: url(assets/images/Checkbox-active-14x14px.svg) !important;
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: $color-medinmind-black;
    font-size: 16px;
    font-family: $font-family-avenir-book;
    font-weight: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.25;
}

address {
    font-style: normal;
}

img {
    display: block;
}

p {
    margin: 16px 0;
}

strong {
    font-family: $font-family-avenir-black;
    font-weight: normal;
}

#app {
    height: 100%;
}

.background {
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    height: calc(100% - 48px);
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 60%);
    }
    &.no-gradient {
        &:after {
            display: none;
        }
    }
    &.no-video {
        background-repeat: no-repeat;
        background-size: cover;
        video {
            display: none;
        }
    }
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background-position: center center;
        background-size: contain;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }
}

.button {
    display: inline-block;
    padding: 8px 40px;
    border: 0 none;
    cursor: pointer;
    font-family: $font-family-avenir-black;
    font-size: 16px;
    font-weight: normal;
    outline: none;
    text-decoration: none;
    &.button-red {
        background-color: $color-medinmind-red;
        color: $color-medinmind-white;
    }
    &.button-white {
        background-color: $color-medinmind-white;
        color: $color-medinmind-red;
    }
}

.gm-style {
    div {
        &:nth-child(3) {
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
}

.hidden-on-desktop {
    @media screen and (min-width: 768px) and (min-height: 512px) {
        display: none;
    }
}

.hidden-on-mobile {
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        display: none;
    }
}

.page {
    position: relative;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 96px 72px 48px 72px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 72px 16px 16px 16px;
    }
}

.page-frame {
    position: absolute;
    left: 0;
    top: 48px;
    width: 0;
    height: 0;
    box-shadow: inset 0 0 0 12px $color-medinmind-red;
    opacity: 0;
    transition: opacity 200ms;
    &.active {
        width: 100%;
        height: calc(100% - 48px);
        opacity: 1;
    }
}

.page-indicators {
    position: fixed;
    right: 24px + 8px;
    top: 120px;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        display: none;
    }
    a {
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 0 24px 0;
        background-color: $color-medinmind-white;
        border: 1px solid $color-medinmind-red;
        border-radius: 50%;
        &:last-child {
            margin-bottom: 0;
        }
        &.active {
            background-color: $color-medinmind-red;
        }
    }
}

.page-title {
    display: inline;
    position: relative;
    margin: 0 32px;
    font-family: $font-family-avenir-medium;
    font-size: 48px;
    font-weight: normal;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        margin: 0 24px;
        font-size: 24px;
    }
    @media screen and (max-width: 511px) {
        display: block;
    }
    &:before {
        content: '[';
        position: absolute;
        left: -32px;
        top: 0;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-medium;
        font-size: 48px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            left: -16px;
            font-size: 24px;
        }
    }
    &:after {
        content: ']';
        position: absolute;
        right: -32px;
        bottom: 0;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-medium;
        font-size: 48px;
        font-weight: normal;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            right: -16px;
            font-size: 24px;
        }
    }
}

.page-title-container {
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        text-align: center;
    }
}

.scroll-indicator {
    position: fixed;
    left: 50%;
    bottom: 24px;
    width: 32px;
    height: 32px;
    animation: scroll-indicator-bounce-up-down 2s infinite;
    background-image: url(assets/images/ic_scroll.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $color-medinmind-white;
    cursor: pointer;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 200ms;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        display: none;
    }
    &.active {
        opacity: 1;
    }
}

@keyframes scroll-indicator-bounce-up-down {
    0% {
        transform: translate3d(-50%, 0, 0);
    }
    50% {
        transform: translate3d(-50%, 25%, 0);
    }
    100% {
        transform: translate3d(-50%, 0, 0);
    }
}

</style>
