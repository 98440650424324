import Vue from 'vue';
import VueRouter from 'vue-router';

import Datenschutz from '../views/Datenschutz';
import Impressum from '../views/Impressum.vue';
import Start from '../views/Start.vue';

Vue.use(VueRouter);

const routes = [
    {
        path : '/',
        name : 'start',
        component : Start,
    },
    {
        path : '/datenschutz',
        name : 'datenschutz',
        component : Datenschutz,
    },
    {
        path : '/impressum',
        name : 'impressum',
        component : Impressum,
    },
];

const router = new VueRouter({
    mode : 'history',
    routes,
    scrollBehavior(to){
        if (to.hash) {
            const pageElement = document.querySelector(to.hash);
            return document.querySelector('#start').scrollTo({
                behavior : 'smooth',
                top : pageElement ? pageElement.offsetTop : 0,
            });
        }
        return {
            x : 0,
            y : 0,
        }
    },
});

export default router;
