<template>
    <section id="unsere-familie" class="page">
        <div class="background no-gradient"></div>
        <div class="layout-container">
            <div class="layout-row">
                <div class="layout-col-4">
                </div>
                <div class="layout-col-4">

                    <div class="layout-row">
                        <div class="layout-col-8">

                            <div class="family-title-container">
                                <div class="page-title-container">
                                    <h1 class="page-title">
                                        Unsere Familie
                                    </h1>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="layout-row">
                <div class="layout-col-4 layout-flex">

                    <div class="family-logo-container">
                        <div class="family-logo-medictum-container">
                            <img
                                alt="medictum"
                                class="logo-medictum"
                                src="../assets/images/medictum.svg"
                            >
                        </div>
                        <div class="family-logo-medictum-member-container">
                            <img
                                alt="DigitalSpital"
                                class="logo-digitalspital"
                                src="../assets/images/DigitalSpital.svg"
                            >
                            <img
                                alt="Herzschlag"
                                class="logo-herzschlag"
                                src="../assets/images/Herzschlag.svg"
                            >
                            <img
                                alt="med in mind"
                                class="logo-med-in-mind"
                                src="../assets/images/med-in-mind.svg"
                            >
                            <img
                                alt="Medizin & Markt"
                                class="logo-medizin-und-markt"
                                src="../assets/images/Medizin-und-Markt.svg"
                            >
                        </div>
                    </div>

                </div>
                <div class="layout-col-4">

                    <div class="family-text-container">
                        <h3>
                            Bei uns bleiben Ihre Themen in der Familie.
                        </h3>
                        <p>
                            Was immer Sie medizinisch-wissenschaftlich und kommunikativ benötigen, lösen wir unter einem
                            Dach.
                        </p>
                        <p>
                            Mehr Infos unter
                            <a
                                href="https://www.medictum.de"
                                rel="noopener"
                                target="_blank"
                            >www.medictum.de</a>
                        </p>
                        <h3>
                            Vernetzen Sie sich mit uns!
                        </h3>
                        <div class="social-links">
                            <a
                                class="social-link"
                                href="https://www.facebook.com/medictum.de"
                                rel="noopener"
                                target="_blank"
                            >
                                <img
                                    alt="Facebook"
                                    src="../assets/images/ic_facebook.svg"
                                >
                            </a>
                            <a
                                class="social-link"
                                href="https://www.instagram.com/inside_medictum/"
                                rel="noopener"
                                target="_blank"
                            >
                                <img
                                    alt="Instagram"
                                    src="../assets/images/ic_instagram.svg"
                                >
                            </a>
                            <a
                                class="social-link"
                                href="https://www.linkedin.com/company/medictum/"
                                rel="noopener"
                                target="_blank"
                            >
                                <img
                                    alt="LinkedIn"
                                    src="../assets/images/ic_linkedin.svg"
                                >
                            </a>
                            <a
                                class="social-link"
                                href="https://www.linkedin.com/company/medictum/"
                                rel="noopener"
                                target="_blank"
                            >
                                <img
                                    alt="Twitter"
                                    src="../assets/images/ic_twitter.svg"
                                >
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#unsere-familie'
        }"></div>
    </section>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
        window.console.log('UnsereFamilie mounted');
    },
    updated(){
    },

    computed : {},

    data(){
        return {};
    },

    methods : {},

    props : [],

    watch : {},

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#unsere-familie {
    display: flex;
    flex-direction: column;
}

.family-logo-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 12px);
    max-width: 800px;
    margin: 32px auto 0 auto;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        align-self: flex-start;
        flex-direction: row;
        width: auto;
        margin: 0 0 0 auto;
        padding: 0 32px 72px 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        padding: 0 48px 72px 0;
    }
    @media screen and (max-height: 511px) {
        max-width: 400px;
        margin: 12px auto 0 auto;
    }
}

.family-logo-medictum-container {
    position: relative;
    padding: 0 0 16px 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        margin: 0 0 0 auto;
        padding: 0 32px 0 0;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        padding: 0 48px 0 0;
    }
    @media screen and (max-height: 511px) {
        padding: 0 0 8px 0;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $color-medinmind-grey;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            left: auto;
            bottom: auto;
            right: 0;
            top: 0;
            width: 1px;
            height: 100%;
        }
    }
}

.family-logo-medictum-member-container {
    display: flex;
    flex-direction: row;
    padding: 16px 0 0 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto 0 0;
        padding: 0 0 0 24px;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        padding: 0 0 0 32px;
    }
    @media screen and (max-height: 511px) {
        padding: 8px 0 0 0;
    }
}

.family-text-container {
    padding: 32px 0 72px 0;
    text-align: center;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        max-width: 480px;
        padding: 0 0 72px 32px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        padding: 0 0 72px 48px;
    }
    @media screen and (max-height: 511px) {
        padding: 8px 0 0 0;
    }
    h3 {
        margin: 32px 0 16px 0;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-black;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            margin: 48px 0 16px 0;
            text-align: left;
        }
        @media screen and (max-height: 511px) {
            margin: 16px 0 4px 0;
            font-size: 14px;
        }
        &:first-child {
            margin-top: 0;
            @media screen and (min-width: 768px) and (min-height: 512px) {
                margin-top: -0.25em;
            }
        }
        &:last-of-type {
            @media screen and (max-height: 511px) {
                display: none;
            }
        }
    }
    p {
        color: $color-medinmind-black;
        font-family: $font-family-avenir-black;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            text-align: left;
        }
        @media screen and (max-height: 511px) {
            display: inline;
            font-size: 14px;
        }
        a {
            color: $color-medinmind-black;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.family-title-container {
    @media screen and (min-width: 768px) and (min-height: 512px) {
        padding: 0 0 48px 32px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        padding: 0 0 48px 48px;
    }
}

.layout-col-1 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 12.5%;
        min-width: 12.5%;
        max-width: 12.5%;
    }
}

.layout-col-2 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
    }
}

.layout-col-3 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 37.5%;
        min-width: 37.5%;
        max-width: 37.5%;
    }
}

.layout-col-4 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }
}

.layout-col-5 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 62.5%;
        min-width: 62.5%;
        max-width: 62.5%;
    }
}

.layout-col-6 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 75%;
        min-width: 75%;
        max-width: 75%;
    }
}

.layout-col-7 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 87.5%;
        min-width: 87.5%;
        max-width: 87.5%;
    }
}

.layout-col-8 {
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}

.layout-container {
    @media screen and (min-width: 768px) and (min-height: 512px) {
        margin: auto 0;
    }
}

.layout-flex {
    display: flex;
}

.layout-row {
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        flex-direction: row;
    }
}

.logo-medictum {
    width: 144px;
    height: auto;
    margin: 0 auto;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 240px * 0.6;
        margin: 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 240px * 0.75;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 240px;
    }
    @media screen and (max-height: 511px) {
        width: 96px;
    }
}

.logo-digitalspital {
    width: calc(30% - 40px);
    height: auto;
    margin: 0 40px 0 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 240px * 0.6;
        margin: 0 0 16px 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 240px * 0.75;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 240px;
        margin: 0 0 24px 0;
    }
    @media screen and (max-width: 511px) {
        width: calc(30% - 20px);
        margin-right: 20px;
    }
    @media screen and (max-height: 511px) {
        width: calc(30% - 20px);
        margin-right: 20px;
    }
}

.logo-herzschlag {
    width: calc(25% - 40px);
    height: auto;
    margin: 0 40px 0 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 144px * 0.6;
        margin: 0 0 16px 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 144px * 0.75;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 144px;
        margin: 0 0 24px 0;
    }
    @media screen and (max-width: 511px) {
        width: calc(25% - 20px);
        margin-right: 20px;
    }
    @media screen and (max-height: 511px) {
        width: calc(25% - 20px);
        margin-right: 20px;
    }
}

.logo-med-in-mind {
    width: calc(25% - 40px);
    height: auto;
    margin: 0 40px 0 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 199px * 0.6;
        margin: 0 0 16px 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 199px * 0.75;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 199px;
        margin: 0 0 24px 0;
    }
    @media screen and (max-width: 511px) {
        width: calc(25% - 20px);
        margin-right: 20px;
    }
    @media screen and (max-height: 511px) {
        width: calc(25% - 20px);
        margin-right: 20px;
    }
}

.logo-medizin-und-markt {
    width: calc(20%);
    height: auto;
    margin: 0;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        width: 96px * 0.6;
        margin: 0;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        width: 96px * 0.75;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 96px;
    }
    @media screen and (max-height: 511px) {
        width: calc(20%);
    }
}

.logo-med-in-mind-member-of-medictum {
    @media screen and (min-width: 768px) and (min-height: 512px) {
    }
}

.page {
    padding: 96px 72px 48px 72px;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
    @media screen and (max-width: 959px) {
        padding: 96px 72px 48px 48px;
    }
    @media screen and (max-height: 511px) {
        padding: 72px 16px 16px 16px;
    }
}

.page-title {
    margin: 0 24px;
    font-size: 32px;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        margin: 0 24px;
        font-size: 24px;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        margin: 0 32px;
        font-size: 48px;
    }
    &:before {
        left: -24px;
        font-size: 32px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            left: -16px;
            font-size: 24px;
        }
        @media screen and (min-width: 1280px) and (min-height: 512px) {
            left: -32px;
            font-size: 48px;
        }
    }
    &:after {
        right: -24px;
        font-size: 32px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            right: -16px;
            font-size: 24px;
        }
        @media screen and (min-width: 1280px) and (min-height: 512px) {
            right: -32px;
            font-size: 48px;
        }
    }
}

.social-links {
    display: flex;
    flex-direction: row;
    @media screen and (max-height: 511px) {
        display: none;
    }
    .social-link {
        display: block;
        margin: 0 4px 0 0;
        &:first-child {
            margin-left: auto;
            @media screen and (min-width: 768px) and (min-height: 512px) {
                margin-left: -12px;
            }
        }
        &:last-child {
            margin-right: auto;
            @media screen and (min-width: 768px) and (min-height: 512px) {
                margin-right: 0;
            }
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
}

</style>
