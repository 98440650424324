<template>
    <section id="was-wir-fuer-sie-tun-koennen" class="page">
        <div class="background no-video">
            <video autoplay loop muted>
                <source
                    src="../assets/videos/1013967371-hd.mp4"
                    type="video/mp4"
                >
            </video>
        </div>
        <div class="articles"
             @mouseover="articleUpdatePause = true"
             @mouseout="articleUpdatePause = false"
        >
            <div class="page-title-container">
                <h1 class="page-title">
                    Was wir für Sie tun können
                </h1>
            </div>
            <h2 class="title">
                Healthcare-Kommunikation strategisch und kreativ.
            </h2>
            <p class="strategy">
                Auf Basis Ihres Briefings und einer detaillierten Analyse entwickeln wir die richtige
                Kommunikationsstrategie, um Ihre Produkte und Dienstleistungen erfolgreich zu vermarkten. Dabei haben
                wir Ihre Ziele stets im Blick. Von der Analyse bis zur Evaluation:
            </p>
            <img
                alt="Leidenschaft macht den Unterschied"
                class="claim"
                src="../assets/images/leidenschaft-macht-den-unterschied.svg"
            />
            <div class="article-container">
                <article
                    :class="{ 'active' : articleIndex === 0 }"
                    @click="articlePopup = 'unsere-zielgruppen-und-influencer'"
                    :style="{ 'margin-left' : 'calc(-' + (articleIndex * 100) + '% - 24px * ' + articleIndex + ')' }"
                >
                    <h3>
                        Unsere Zielgruppen<br>
                        und Influencer
                    </h3>
                    <div class="article-action"></div>
                </article>
                <article
                    :class="{ 'active' : articleIndex === 1 }"
                    @click="articlePopup = 'fachbereich'"
                >
                    <h3>
                        Fachbereich
                    </h3>
                    <div class="article-action"></div>
                </article>
                <article
                    :class="{ 'active' : articleIndex === 2 }"
                    @click="articlePopup = 'publikum'"
                >
                    <h3>
                        Publikum
                    </h3>
                    <div class="article-action"></div>
                </article>
            </div>
            <nav class="article-indicators">
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 0 }"
                    @click="toArticleIndex(0)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 1 }"
                    @click="toArticleIndex(1)"
                ></div>
                <div
                    class="article-indicator"
                    :class="{ 'active' : articleIndex === 2 }"
                    @click="toArticleIndex(2)"
                ></div>
            </nav>
        </div>
        <aside
            class="popup popup-unsere-zielgruppen-und-influencer"
            :style="{ 'display' : articlePopup === 'unsere-zielgruppen-und-influencer' ? 'block' : 'none' }"
        >
            <div class="popup-header">
                <div class="popup-title-container">
                    <h3 class="popup-title">
                        Was wir für Sie tun können -
                        <strong>Unsere Zielgruppen und Influencer</strong>
                    </h3>
                </div>
                <div
                    class="popup-action"
                    @click.stop="articlePopup = ''"
                ></div>
            </div>
            <div class="roller">
                <div class="roller-animation-container">
                    <ul>
                        <li
                            v-for="(rollerItem, index) in [].concat(
                                rollerItems['unsere-zielgruppen-und-influencer'],
                                rollerItems['unsere-zielgruppen-und-influencer'],
                                rollerItems['unsere-zielgruppen-und-influencer'],
                                rollerItems['unsere-zielgruppen-und-influencer'],
                                rollerItems['unsere-zielgruppen-und-influencer'],
                            )"
                            :key="index"
                        >
                            <h4
                                v-html="rollerItem.title"
                            >
                            </h4>
                            <h5
                                v-if="rollerItem.subTitle"
                                v-html="rollerItem.subTitle"
                            ></h5>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
        <aside
            class="popup popup-fachbereich"
            :style="{ 'display' : articlePopup === 'fachbereich' ? 'block' : 'none' }"
        >
            <div class="popup-header">
                <div class="popup-title-container">
                    <h3 class="popup-title">
                        Was wir für Sie tun können -
                        <strong>Fachbereich</strong>
                    </h3>
                </div>
                <div
                    class="popup-action"
                    @click.stop="articlePopup = ''"
                ></div>
            </div>
            <div class="roller">
                <div class="roller-animation-container">
                    <ul>
                        <li
                            v-for="(rollerItem, index) in [].concat(
                                rollerItems['fachbereich'],
                                rollerItems['fachbereich'],
                                rollerItems['fachbereich'],
                                rollerItems['fachbereich'],
                                rollerItems['fachbereich'],
                            )"
                            :key="index"
                        >
                            <h4
                                v-html="rollerItem.title"
                            >
                            </h4>
                            <h5
                                v-if="rollerItem.subTitle"
                                v-html="rollerItem.subTitle"
                            ></h5>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
        <aside
            class="popup popup-publikum"
            :style="{ 'display' : articlePopup === 'publikum' ? 'block' : 'none' }"
        >
            <div class="popup-header">
                <div class="popup-title-container">
                    <h3 class="popup-title">
                        Was wir für Sie tun können -
                        <strong>Publikum</strong>
                    </h3>
                </div>
                <div
                    class="popup-action"
                    @click.stop="articlePopup = ''"
                ></div>
            </div>
            <div class="roller">
                <div class="roller-animation-container">
                    <ul>
                        <li
                            v-for="(rollerItem, index) in [].concat(
                                rollerItems['publikum'],
                                rollerItems['publikum'],
                                rollerItems['publikum'],
                                rollerItems['publikum'],
                                rollerItems['publikum'],
                            )"
                            :key="index"
                        >
                            <h4
                                v-html="rollerItem.title"
                            >
                            </h4>
                            <h5
                                v-if="rollerItem.subTitle"
                                v-html="rollerItem.subTitle"
                            ></h5>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
        <div class="page-frame" :class="{
            'active' : $root.states.isScrolling === true && $root.states.activeScrollSection === '#was-wir-fuer-sie-tun-koennen'
        }"></div>
    </section>
</template>

<script>

import anime from 'animejs/lib/anime.es.js';
import Hammer from 'hammerjs';

const RESIZE_TIMEOUT_DELAY_IN_MS = 100;
const ROLLER_INTERVAL_DELAY_IN_MS = 1000;
const UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS = 3000;

export default {

    components : {},

    created(){
    },
    destroyed(){
        window.removeEventListener('resize', this.onResize);
        this.reset();
        this.articleContainerHammer.off('swipeleft swiperight');
        this.articleContainerHammer.destroy();
    },
    mounted(){
        window.console.log('WasWirFuerSieTunKoennen mounted');
        window.addEventListener('resize', this.onResize);
        this.articleContainerHammer = new Hammer(this.$el.querySelector('.article-container'));
        this.articleContainerHammer.on('swipeleft', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toNextArticle();
        });
        this.articleContainerHammer.on('swiperight', ()=>{
            window.clearInterval(this.articleUpdateInterval);
            this.toPreviousArticle();
        });
        if (this.$route.hash === '#was-wir-fuer-sie-tun-koennen') {
            this.init();
        }

        // NOTE: Due to an issue with Chrome where the offsetHeight of one of the roller item <li> elements is not
        // calculated correctly, delaying the calculation with a timeout is a simple workaround.

        window.setTimeout(()=>{
            this.calculateOffsetHeights();
        }, 100);
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            articleContainerHammer : null,
            articleIndex : 0,
            articlePopup : null,
            articleUpdateInterval : null,
            articleUpdatePause : true,
            resizeTimeout : null,
            rollerAnimation : null,
            rollerIndex : 0,
            rollerInterval : null,
            rollerItems : {
                'unsere-zielgruppen-und-influencer' : [
                    {
                        offsetHeight : 0,
                        subTitle : 'API, Fachärzte, Praxispersonal',
                        title : 'Humanmedizin',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Groß- & Kleintier',
                        title : 'Veterinär&shy;medizin',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Apotheker, PTA, PKA',
                        title : 'Pharmazie',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Patienten, Angehörige, Interessierte',
                        title : 'Endverbraucher',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Fach & Publikum',
                        title : 'Medien (Print & Online)',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Fachgesellschaften & Berufsverbände, KOL',
                        title : 'Wissenschaft',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : 'Chef- & Oberärzte, Management, Einkauf, Pflegepersonal',
                        title : 'Krankenhäuser & Kliniken',
                    },
                ],
                'fachbereich' : [
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Proaktive Medienarbeit',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Agendasetting',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Verlagskontakte',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Experten-Workshops, Symposien & Presseevents',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Moderation',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Medizin- & Wissenschafts&shy;journalismus',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'KOL-Management',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Service&shy;materialien für Fachgruppen',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Videoproduktion',
                    },
                ],
                'publikum' : [
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Journalisten-Networking',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Themen&shy;kooperationen & -settings',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Storytelling',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Content&shy;erstellung',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Kampagnen & Initiativen',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Presseevents',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Infoflyer, Broschüren etc.',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Bewegtbild',
                    },
                    {
                        offsetHeight : 0,
                        subTitle : '',
                        title : 'Hörfunk',
                    },
                ],
            },
        };
    },

    methods : {
        calculateOffsetHeights(){
            Object.keys(this.rollerItems).forEach((articlePopup)=>{
                let setDisplayToNone = false;
                const popupElement = this.$el.querySelector('.popup-' + articlePopup);
                if (popupElement.style.display !== 'block') {
                    popupElement.style.display = 'block';
                    setDisplayToNone = true;
                }
                this.$el.querySelectorAll('.popup-' + articlePopup + ' li').forEach((element, i)=>{
                    if (this.rollerItems[articlePopup][i]) {
                        this.rollerItems[articlePopup][i].offsetHeight = element.offsetHeight;
                    }
                    window.console.log(this.rollerItems[articlePopup][i]);
                });
                if (setDisplayToNone) {
                    popupElement.style.display = 'none';
                }
            });
        },
        getCalculatedOffsetHeightForRollerItems(articlePopup, rollerIndex){
            rollerIndex = rollerIndex * -1;
            let offsetHeight = 0;
            this.rollerItems[articlePopup].forEach((rollerItem, i)=>{
                if (i < rollerIndex) {
                    offsetHeight += rollerItem.offsetHeight;
                }
            });
            offsetHeight = offsetHeight * -1;
            window.console.log('getCalculatedOffsetHeightForRollerItems', rollerIndex, offsetHeight);
            return offsetHeight;
        },
        init(){
            this.initArticleUpdateInterval();
        },
        initArticlePopup(){
            window.clearInterval(this.rollerInterval);
            if (this.rollerAnimation) {
                this.rollerAnimation.pause();
            }
            this.rollerAnimation = anime({
                duration : 0,
                targets : this.$el.querySelectorAll('.roller-animation-container'),
                translateY : 0,
            });
            this.rollerIndex = 0;
            if (this.articlePopup) {
                this.rollerInterval = setInterval(()=>{
                    this.updateRoller();
                }, ROLLER_INTERVAL_DELAY_IN_MS);
            }
        },
        initArticleUpdateInterval(){
            window.clearInterval(this.articleUpdateInterval);
            this.articleUpdatePause = false;
            this.articleUpdateInterval = window.setInterval(()=>{
                if (this.articleUpdatePause === true) {
                    return;
                }
                if (
                    this.$root.states.breakpoints.maxWidth959 === false &&
                    this.$root.states.breakpoints.maxHeight767 === false
                ) {
                    return;
                }
                if (this.articlePopup) {
                    return;
                }
                this.updateArticle();
            }, UPDATE_ARTICLE_INTERVAL_DELAY_IN_MS);
        },
        onResize(){
            window.clearTimeout(this.resizeTimeout);
            this.resizeTimeout = window.setTimeout(()=>{
                this.calculateOffsetHeights();
            }, RESIZE_TIMEOUT_DELAY_IN_MS);
        },
        reset(){
            window.clearInterval(this.articleUpdateInterval);
            window.clearInterval(this.rollerInterval);
            this.articleIndex = 0;
            this.articlePopup = null;
            this.articleUpdatePause = true;
        },
        toArticleIndex(articleIndex){
            this.initArticleUpdateInterval();
            this.articleIndex = articleIndex;
        },
        toNextArticle(){
            if (this.articleIndex < 2) {
                this.articleIndex++;
                return;
            }
            this.articleIndex = 0;
        },
        toPreviousArticle(){
            if (this.articleIndex > 0) {
                this.articleIndex--;
                return;
            }
            this.articleIndex = 2;
        },
        updateArticle(){
            this.toNextArticle();
        },
        updateRoller(){
            if (this.rollerIndex > this.rollerItems[this.articlePopup].length * -1) {
                this.rollerIndex--;
            } else {
                this.rollerIndex = 0;
            }
            if (this.rollerAnimation) {
                this.rollerAnimation.pause();
            }
            window.console.log('updateRoller', this.rollerIndex);
            this.rollerAnimation = anime({
                complete : ()=>{
                    if (this.rollerIndex === 0) {
                        this.updateRoller();
                    }
                },
                duration : this.rollerIndex === 0 ? 0 : 333,
                easing : 'easeInOutSine',
                targets : this.$el.querySelector('.popup-' + this.articlePopup + ' .roller-animation-container'),
                translateY : this.rollerIndex === 0 ? 0 : this.getCalculatedOffsetHeightForRollerItems(this.articlePopup, this.rollerIndex),
            });
        },
    },

    props : [],

    watch : {
        articlePopup(){
            this.initArticlePopup();
        },
        $route(){
            this.reset();
            if (this.$route.hash === '#was-wir-fuer-sie-tun-koennen') {
                this.init();
            }
        },
    },

}

</script>

<style lang="scss" scoped>

@import "../assets/css/variables";

#was-wir-fuer-sie-tun-koennen {
    display: flex;
    flex-direction: column;
}

.articles {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: auto;
    margin: 0 auto;
    text-align: center;
    @media screen and (min-width: 768px) and (min-height: 768px) {
        width: 85%;
        min-width: 400px;
        height: auto;
        margin: auto;
    }
    @media screen and (min-width: 960px) and (min-height: 768px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 768px) {
        width: 75%;
        max-width: none;
    }
    @media screen and (min-width: 1440px) and (min-height: 768px) {
        width: 75%;
        max-width: 1280px;
    }
    @media screen and (min-width: 1920px) and (min-height: 768px) {
    }
}

.article-action {
    display: block;
    width: 46px;
    height: 40px;
    margin: auto auto 0 auto;
    cursor: pointer;
    background-image: url(../assets/images/ic_popup.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @media screen and (max-width: 959px), screen and (max-height: 767px) {
        width: 46px * 0.75;
        height: 40px * 0.75;
    }
    @media screen and (max-height: 511px) {
        display: none;
    }
}

.article-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 24px 24px 60px 24px;
    overflow: hidden;
    @media screen and (min-width: 960px) and (min-height: 768px) {
        overflow: visible;
    }
    article {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        position: relative;
        width: 100%;
        margin: 0 12px;
        padding: 16px;
        background-color: $color-medinmind-white;
        cursor: pointer;
        text-align: center;
        transition: margin-left 400ms;
        will-change: margin-left;
        @media screen and (min-width: 960px) and (min-height: 768px) {
            width: calc(33.33333333% - 24px);
            min-height: 144px;
            margin: 12px !important;
            padding: 16px 16px 24px 16px;
            box-shadow: 0 24px 36px 0 rgba(0, 0, 0, 0.05);
            overflow: visible;
            &:hover {
                box-shadow: 0 24px 36px 0 rgba(0, 0, 0, 0.15);
            }
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            box-shadow: 0 24px 36px 0 rgba(0, 0, 0, 0.05);
        }
        h3 {
            margin: 0 0 16px 0;
            font-size: 20px;
            @media screen and (max-width: 959px), screen and (max-height: 767px) {
                font-size: 16px;
            }
            @media screen and (max-height: 511px) {
                margin: auto;
            }
        }
    }
}

.article-indicators {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    @media screen and (min-width: 960px) and (min-height: 768px) {
        display: none;
    }
    @media screen and (max-height: 511px) {
        display: none;
    }
    .article-indicator {
        display: block;
        width: 12px;
        height: 12px;
        margin: 0 12px 0 0;
        background-color: $color-medinmind-white;
        border: 1px solid $color-medinmind-red;
        border-radius: 50%;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background-color: $color-medinmind-red;
        }
    }
}

.background {
    &.no-video {
        background-image: url(../assets/images/1013967371-hd.jpg);
    }
}

.claim {
    display: block;
    width: 95%;
    max-width: 400px;
    height: auto;
    margin: 24px auto 0 auto;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        max-width: 560px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
        max-width: 640px;
        margin: 32px auto 0 auto;
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        max-width: 720px;
    }
    @media screen and (max-height: 511px) {
        margin: 16px auto -8px auto;
    }
}

.page {
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        padding: 72px 24px 0 24px;
    }
}

.page-title {
    font-size: 32px;
    @media screen and (max-width: 767px), screen and (max-height: 511px) {
        font-size: 24px;
    }
    @media screen and (max-width: 511px) {
        display: block;
    }
    &:before {
        font-size: 32px;
        left: -24px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            left: -16px;
            font-size: 24px;
        }
    }
    &:after {
        font-size: 32px;
        right: -24px;
        @media screen and (max-width: 767px), screen and (max-height: 511px) {
            right: -32px;
            font-size: 24px;
        }
    }
}

.page-title-container {
    margin-bottom: 24px;
    @media screen and (max-width: 959px), screen and (max-height: 767px) {
        margin-bottom: 16px;
    }
}

.popup {
    display: none;
    position: fixed;
    right: 24px;
    top: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background-color: $color-medinmind-red;
    box-shadow: 0 0 48px rgba(0, 0, 0, .25);
    z-index: 2;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        right: 0;
        top: 0;
        width: 75%;
        height: 100%;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
        width: 60%;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 96px;
        width: 100%;
        height: 25%;
        background-image: linear-gradient(to bottom, rgba(228, 0, 61, 1) 0%, rgba(228, 0, 61, 0) 100%);
        z-index: 2;
        @media screen and (min-width: 1920px) and (min-height: 768px) {
            top: 144px;
        }
        @media screen and (max-height: 511px) {
            top: 72px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;
        background-image: linear-gradient(to top, rgba(228, 0, 61, 1) 0%, rgba(228, 0, 61, 0) 100%);
        z-index: 2;
    }
    &.active {
        display: block;
    }
}

.popup-action {
    display: block;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-image: url(../assets/images/ic_x_e4003d.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        right: 24px;
    }
}

.popup-header {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 96px;
    background-color: $color-medinmind-white;
    @media screen and (min-width: 1920px) and (min-height: 768px) {
        height: 144px;
    }
    @media screen and (max-height: 511px) {
        height: 72px;
    }
}

.popup-title {
    display: inline;
    position: relative;
    margin: 0;
    color: $color-medinmind-black;
    font-family: $font-family-avenir-medium;
    font-size: 16px;
    font-weight: normal;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        font-size: 24px;
    }
    &:before {
        content: '[';
        position: absolute;
        left: -12px;
        top: 0;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-medium;
        font-size: 16px;
        font-weight: normal;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            left: -16px;
            font-size: 24px;
        }
    }
    &:after {
        content: ']';
        position: absolute;
        right: -12px;
        bottom: 0;
        color: $color-medinmind-red;
        font-family: $font-family-avenir-medium;
        font-size: 16px;
        font-weight: normal;
        @media screen and (min-width: 768px) and (min-height: 512px) {
            right: -16px;
            font-size: 24px;
        }
    }
}

.popup-title-container {
    margin: auto 48px auto 24px;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        margin: auto 96px auto 48px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1440px) and (min-height: 512px) {
        margin-left: 96px;
    }
    @media screen and (min-width: 1920px) and (min-height: 768px) {
        margin-left: 144px;
    }
}

.roller {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 96px;
    width: 100%;
    height: calc(100% - 96px);
    padding: 0 24px 0 24px;
    overflow: hidden;
    @media screen and (min-width: 768px) and (min-height: 512px) {
        padding: 0 48px 0 48px;
    }
    @media screen and (min-width: 960px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1280px) and (min-height: 512px) {
    }
    @media screen and (min-width: 1440px) and (min-height: 512px) {
        padding-left: 96px;
    }
    @media screen and (min-width: 1920px) and (min-height: 768px) {
        top: 144px;
        height: calc(100% - 144px);
        padding-left: 144px;
    }
    @media screen and (max-height: 511px) {
        top: 72px;
        height: calc(100% - 72px);
    }
    ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0;
            padding: 0 0 12px 0;
            h4 {
                margin: 0;
                padding: 12px 0 0 0;
                color: $color-medinmind-white;
                font-family: $font-family-avenir-black;
                font-size: 24px;
                font-weight: normal;
                line-height: 1;
                @media screen and (min-width: 768px) and (min-height: 512px) {
                    font-size: 32px;
                }
            }
            h5 {
                margin: 0;
                padding: 4px 0 0 0;
                color: $color-medinmind-white;
                font-family: $font-family-avenir-black;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.4;
                @media screen and (min-width: 768px) and (min-height: 512px) {
                    font-size: 16px;
                }
            }
        }
    }
}

.strategy {
    margin: 0 0 16px 0;
    font-size: 24px;
    @media screen and (max-width: 959px), screen and (max-height: 511px) {
        font-size: 14px;
    }
}

.title {
    margin: 0 0 12px 0;
    color: $color-medinmind-red;
    font-family: $font-family-avenir-medium;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    @media screen and (max-width: 959px), screen and (max-height: 511px) {
        margin: 0 0 4px 0;
        font-size: 14px;
    }
}

</style>
