<template>
    <div id="impressum">
        <div class="legal">
            <div class="legal-title-container">
                <h2>
                    Impressum
                </h2>
            </div>
            <div class="legal-container">
                <div>
                    <address>
                        med in mind GmbH<br>
                        Dachauer Straße 38<br>
                        80335 München
                    </address>
                    <p>
                        Tel:
                        <a href="tel:+491775989622">+49 177 5989622</a>
                        <br>
                        E-Mail:
                        <a href="mailto:info@med-in-mind.de">info@med-in-mind.de</a>
                    </p>
                    <h4>
                        Geschäftsführung:
                    </h4>
                    <p class="no-margin">
                        Kirsten Schwarz
                    </p>
                </div>
                <div>
                    <h4>
                        Handelsregister:
                    </h4>
                    <p class="no-margin">
                        Registergericht München,<br>
                        Registernummer HRB 187455
                    </p>
                    <h4>
                        Umsatzsteuer-ID:
                    </h4>
                    <p class="no-margin">
                        DE 274468941
                    </p>
                </div>
                <div>
                    <h4>
                        Verantwortlich für
                        www.med-in-mind.de
                        i.S.v. § 5 TMG:
                    </h4>
                    <p class="no-margin">
                        Kirsten Schwarz
                    </p>
                </div>
            </div>
            <div class="legal-nav-container">
                <p>
                    Zurück zur
                </p>
                <p>
                    <router-link
                        class="button button-red"
                        :to="{ name: 'start' }"
                    >
                        Startseite
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    components : {},

    created(){
    },
    destroyed(){
    },
    mounted(){
        window.console.log('Impressum mounted');
        this.updateMetaTags();
    },
    updated(){
    },

    computed : {},

    data(){
        return {
            metaTags : {
                'meta[name="description"]' : '',
                'meta[property="og:description"]' : '',
                'meta[property="og:title"]' : '',
                'meta[property="og:url"]' : 'http://www.med-in-mind.de/impressum',
            },
        };
    },

    methods : {
        updateMetaTags(){
            window.console.log('updateMetaTags', this.$route.path, this.metaTags);
            if (this.metaTags) {
                for (let [key, value] of Object.entries(this.metaTags)) {
                    document.querySelector(key).setAttribute('content', value);
                }
            }
        },
    },

    props : [],

    watch : {},

}

</script>

<style lang="scss">

@import "../assets/css/variables";

#impressum {
    padding: 96px 120px 48px 72px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        padding: 80px 32px 32px 32px;
    }
}

.legal {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.legal-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        display: block;
        margin: 0;
    }
    @media screen and (min-width: 1280px) {
        width: 80%;
    }
    @media screen and (min-width: 1440px) {
        width: 66.66666666%;
    }
    @media screen and (min-width: 1920px) {
        width: 50%;
    }
    > div {
        width: 33.33333333%;
        padding: 0 12px;
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 32px 0 0 0;
            &:first-child {
                padding-top: 0;
            }
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
    }
    address {
        margin: 32px 0 0 0;
        font-size: 16px;
        line-height: 2;
        @media screen and (max-width: 959px) {
            font-size: 14px;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    h4 {
        margin: 32px 0 0 0;
        line-height: 2;
        @media screen and (max-width: 959px) {
            font-size: 14px;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    p {
        margin: 32px 0 0 0;
        line-height: 2;
        @media screen and (max-width: 959px) {
            font-size: 14px;
        }
        &:first-child {
            margin-top: 0;
        }
        &.no-margin {
            margin-top: 0;
        }
    }
}

.legal-nav-container {
    width: 100%;
    margin: 0 auto;
    padding: 16px 0 0 0;
    @media screen and (max-width: 767px) {
        display: block;
        margin: 0;
    }
    @media screen and (min-width: 1280px) {
        width: 80%;
    }
    @media screen and (min-width: 1440px) {
        width: 66.66666666%;
    }
    @media screen and (min-width: 1920px) {
        width: 50%;
    }
}

.legal-title-container {
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        display: block;
        margin: 0;
    }
    @media screen and (min-width: 1280px) {
        width: 80%;
    }
    @media screen and (min-width: 1440px) {
        width: 66.66666666%;
    }
    @media screen and (min-width: 1920px) {
        width: 50%;
    }
}

</style>
